import React, {useEffect, useState} from "react"
import {Table, Tbody, Td, Th, Thead, Tr} from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import ProgressView from "../ProgressView"
import TamashaButtonLink from "../TamashaButtonLink";
import CustomPagination from "../Pagination";
import useDebounce from "../../hooks/useDebounce";
import SearchAndFilter from "../SearchAndFilter";
import {useSkills} from "../../hooks/useSkills";

const SkillsTable = ({questId = "", categoryId = ""}) => {

    const {loadSkills, skills, loading, loadingStates} = useSkills()
    const [page, setPage] = useState(1)
    const [query, setQuery] = useState('')
    const [records, setRecords] = useState(10)

    const debouncedQuery = useDebounce(query, 500)

    useEffect(() => {
        loadSkills(debouncedQuery ? 0 : skills.length, debouncedQuery, records, questId, categoryId)
    }, [page, debouncedQuery, records, questId, categoryId])

    useEffect(() => {
        setPage(0)
    }, [records])

    return <>

        <SearchAndFilter onQuery={query => setQuery(query)} onRecords={records => setRecords(records)}/>

        {loading[loadingStates.skills] &&
        <div className={"p-2 text-center"}>
            <ProgressView/>
        </div>
        }

        {!loading[loadingStates.skills] && skills.length === 0 &&
        <div className={"p-2 text-center"}>
            <p className="mb-2 text-muted">No data available</p>
        </div>
        }

        {skills.length > 0 &&
        <div className="mt-3">

            <div className="table-rep-plugin">
                <div
                    className="table-responsive mb-0"
                    data-pattern="priority-columns"
                >
                    <Table className="table table-striped table-bordered">
                        <Thead>
                            <Tr>
                                <Th data-priority="1">Name</Th>
                                <Th data-priority="1">Description</Th>
                                <Th data-priority="1">Reward points</Th>
                                <Th data-priority="4"></Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {skills.map((skill, b) =>
                                <Tr key={b.toString()}>
                                    <Td>{skill.title}</Td>
                                    <Td>{skill.description}</Td>
                                    <Td>{skill.reward_points}</Td>
                                    <Td>
                                        <TamashaButtonLink
                                            to={{pathname: "/dashboard/skills/" + skill.id, state: {skill}}}
                                            outline={true}
                                            color="primary"
                                            extraClassName={'btn-sm btn-rounded'}>
                                            Details
                                        </TamashaButtonLink>
                                    </Td>
                                </Tr>
                            )}
                        </Tbody>
                    </Table>

                    {!debouncedQuery &&
                    <CustomPagination
                        onPrevious={() => setPage(skills.length - records)}
                        onNext={() => setPage(skills.length)}
                    />
                    }

                </div>
            </div>
        </div>
        }
    </>

}

export default SkillsTable
