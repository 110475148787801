import React, {useEffect, useState} from "react"
import {Table, Tbody, Td, Th, Thead, Tr} from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import ProgressView from "../ProgressView"
import CustomPagination from "../Pagination";
import useDebounce from "../../hooks/useDebounce";
import {usePortfolio} from "../../hooks/usePortfolio";
import {getFormarttedNumber} from "../../util/Util";
import {Link} from "react-router-dom";

const PortfolioTable = ({ownerId = "", projectID = "", userId = ""}) => {

    const {loadPortfolio, portfolio, loading, loadingStates} = usePortfolio()
    const [page, setPage] = useState(1)
    const [query, setQuery] = useState('')
    const [records, setRecords] = useState(10)

    const debouncedQuery = useDebounce(query, 500)

    useEffect(() => {
        loadPortfolio(page, debouncedQuery, projectID, ownerId, userId, records)
    }, [records, ownerId, userId, projectID, page])

    useEffect(() => {
        setPage(0)
    }, [records])

    return <>

        {loading[loadingStates.portfolio] &&
        <div className={"p-2 text-center"}>
            <ProgressView/>
        </div>
        }

        {!loading[loadingStates.portfolio] && portfolio.length === 0 &&
        <div className={"p-2 text-center"}>
            <p className="mb-2 text-muted">No data available</p>
        </div>
        }

        {portfolio.length > 0 &&
        <div className="mt-3">

            <div className="table-rep-plugin">
                <div
                    className="table-responsive mb-0"
                    data-pattern="priority-columns"
                >
                    <Table className="table table-striped table-bordered">
                        <Thead>
                            <Tr>
                                <Th data-priority="1">Name</Th>
                                <Th data-priority="1">Project</Th>
                                <Th data-priority="1">Amount Pledged (EUR)</Th>
                                <Th data-priority="1">Status</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {portfolio.map((port, b) =>
                                <Tr key={b.toString()}>
                                    <Td>
                                    <Link
                                        to={{
                                            pathname: "/dashboard/accounts/" + port.user_id,
                                            state: {account: port.investor_details}
                                        }}>
                                        {port.investor_details.first_name} {port.investor_details.last_name}
                                    </Link>
                                    </Td>
                                    <Td><Link
                                        to={{
                                            pathname: "/dashboard/projects/" + port.project_id,
                                            state: {project: port.project_details}
                                        }}>
                                        {port.project_details?.name}
                                    </Link>
                                    </Td>
                                    <Td>{getFormarttedNumber(port.amount)}</Td>
                                    <Td>{port.status}</Td>
                                </Tr>
                            )}
                        </Tbody>
                    </Table>

                    {!debouncedQuery &&
                    <CustomPagination
                        onPrevious={() => setPage(portfolio.length - records)}
                        onNext={() => setPage(portfolio.length)}
                    />
                    }

                </div>
            </div>
        </div>
        }
    </>

}

export default PortfolioTable
