import React from "react"

const SelectView = (props) =>
    <div className="mb-3">
        <label htmlFor="floatingSelectGrid">{props.label}</label>
        <select className="form-select" {...props}>
            {props.children}
        </select>
    </div>

export default SelectView
