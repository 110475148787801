const PRODUCTION_URL = "https://maaktaba-api-d002a601cd4a.herokuapp.com"
const DEVELOPMENT_URL = "http://localhost:9090"

export const isStagingEnvironment = () => window.location.host.includes("staging")

export const isLocalEnvironment = () => !process.env.NODE_ENV || process.env.NODE_ENV === 'development' || window.location.host.includes("dev")

export default {
    SERVER_HOST: isLocalEnvironment() ? DEVELOPMENT_URL : PRODUCTION_URL,
    API_URL: '/api/v1/',
    FILE_URL: '/files/',

    LOGIN: 'admin/login',
    LOGOUT: 'logout',
    REFRESH_TOKEN: 'refresh',
    ME: 'me',
    PERMISSIONS: 'permissions',
    CHANGE_PASSWORD: 'admin/update/password',
    REQUEST_RESET_CODE: 'password-reset/request-code',
    VALIDATE_RESET_CODE: 'password-reset/validate-code',
    UPDATE_PASSWORD_RESET: 'password-reset/update-password',
    COUNTRIES: (page, query, records) => `countries?page=${page}&s=${query}&records=${records}`,
    TOWN: 'towns',

    ESTATES: (page, query, records) => `estates?page=${page}&s=${query}&records=${records}`,
    ESTATE: `estates`,
    MY_ESTATE: `my-estate`,

    ESTATE_ADMIN: `estate-admin`,

    UNITS: (estateId, page, query, records) => `units?page=${page}&s=${query}&records=${records}&estate_id=${estateId}`,
    UNIT: `units`,

    ARCHITECTS: (offset, query, records) => `architect/list-admin?offset=${offset}&query=${query}&limit=${records}`,
    ARCHITECT_WITH_ID: id => `architect/${id}`,
    ARCHITECT: `architect`,
    UPDATE_ARCHITECT_DETAILS: 'architect/update/details',
    UPDATE_ARCHITECT_PHOTO: 'architect/update/photo',
    UPDATE_ARCHITECT_COVER: 'architect/update/cover',

    MANUFACTURERS: (offset, query, records) => `manufacturer/list-admin?offset=${offset}&query=${query}&limit=${records}`,
    MANUFACTURER_WITH_ID: id => `manufacturer/${id}`,
    MANUFACTURER: `manufacturer`,
    UPDATE_MANUFACTURER_DETAILS: 'manufacturer/update/details',
    UPDATE_MANUFACTURER_PHOTO: 'manufacturer/update/photo',
    UPDATE_MANUFACTURER_COVER: 'manufacturer/update/cover',

    PROJECTS: ({
                   offset,
                   query,
                   records,
                   projectOwnerId,
                   categoryId,
                   groupId,
                   subCategoryId,
                   propertyId,
                   entityType = "project"
               }) => `project/list-admin?offset=${offset}&query=${query}&limit=${records}
    &project_owner_id=${projectOwnerId}&category_id=${categoryId}&group_id=${groupId}&sub_category_id=${subCategoryId}&property_id=${propertyId}&entity_type=${entityType}`,
    PROJECT_WITH_ID: id => `project/${id}`,
    PROJECT: `project`,
    UPDATE_PROJECT_DETAILS: 'project/update/details',
    UPDATE_PROJECT_PHOTO: 'project/update/photo',
    UPDATE_PROJECT_STATUS: 'project/update/status',
    UPDATE_PROJECT_OPEN_STATUS: 'project/update/open-status',
    DELETE_PROJECT: 'project/delete',

    PORTFOLIOS: (offset, query, records, project_owner_id, project_id, user_id) => `portfolio/list-admin?offset=${offset}&query=${query}
    &limit=${records}&owner_id=${project_owner_id}&project_id=${project_id}&user_id=${user_id}`,
    PORTFOLIO_WITH_ID: id => `portfolio/${id}`,
    PORTFOLIO: `portfolio`,

    SubCategories: (groupID, offset, query, records) => `subCategory?offset=${offset}&query=${query}&limit=${records}&category_id=${groupID}`,
    SubCategory_WITH_ID: id => `subCategory/${id}`,
    SubCategory: `subCategory`,
    UPDATE_SubCategory_DETAILS: 'subCategory/update/details',

    CATEGORIES: (groupID, offset, query, records) => `category?offset=${offset}&query=${query}&limit=${records}&group_id=${groupID}`,
    CATEGORY_WITH_ID: id => `category/${id}`,
    CATEGORY: `category`,
    UPDATE_CATEGORY_DETAILS: 'category/update/details',

    ACCOUNTS: (offset, query, records) => `user/list?offset=${offset}&query=${query}&limit=${records}`,
    ACCOUNT_WITH_ID: id => `user/${id}`,
    UPDATE_ACCOUNT_STATUS: 'user/update/status',

    DASHBOARD: `admin/dashboard`,

    LIST_ROADMAP: (id, year, quarter) => `roadmap/list/admin/${id}?year=${year}&quarter=${quarter}`,
    CREATE_ROADMAP: `roadmap/create`,
    UPDATE_ROADMAP: `roadmap/update`,
    DELETE_ROADMAP: `roadmap/delete`,
    LIST_PROJECT_UPDATES: (id) => `post/list/${id}`,
    POST_PROJECT_UPDATE: `post/create`,
    UPDATE_PROJECT_UPDATE: `post/update`,
    DELETE_PROJECT_UPDATE: `post/delete`,

    QUESTS: (offset, query, records, quest_owner_id, category) => `quest?offset=${offset}&query=${query}&limit=${records}
    &quest_owner_id=${quest_owner_id}&category_id=${category}`,
    QUEST_WITH_ID: id => `quest/${id}`,
    QUEST: `quest`,
    UPDATE_QUEST_DETAILS: 'quest/update/details',
    UPDATE_QUEST_PHOTO: 'quest/update/photo',
    UPDATE_QUEST_STATUS: 'quest/update/status',
    DELETE_QUEST: 'quest/delete',

    SKILLS: (offset, query, records, quest_id, category) => `skill?offset=${offset}&query=${query}&limit=${records}
    &quest_id=${quest_id}&category_id=${category}`,
    SKILL_WITH_ID: id => `skill/${id}`,
    SKILL: `skill`,
    UPDATE_SKILL_DETAILS: 'skill/update/details',
    UPDATE_SKILL_PHOTO: 'skill/update/photo',
    UPDATE_SKILL_STATUS: 'skill/update/status',
    DELETE_SKILL: 'skill/delete',

    GROUPS: (offset, query, records, groupType) => `group?offset=${offset}&query=${query}&limit=${records}&group_type=${groupType}`,
    GROUP_WITH_ID: id => `group/${id}`,
    GROUP: `group`,
    UPDATE_GROUP_DETAILS: 'group/update/details',

    MATERIALS: (offset, query, records) => `material?offset=${offset}&query=${query}&limit=${records}`,
    MATERIAL_WITH_ID: id => `material/${id}`,
    MATERIAL: `material`,
    UPDATE_MATERIAL_DETAILS: 'material/update/details',

    LIST_TRANSACTIONS: (page, query, records, project_id, startDate, startTime, endDate, endTime, status) => `transaction/list?page=${page}&query=${query}&limit=${records}&project_id=${project_id}&start_date=${startDate}&start_time=${startTime}&end_date=${endDate}&end_time=${endTime}&status=${status}`,

    INITIATE_PAYPAL: 'payment/paypal/new',
    CONFIRM_PAYPAL: 'payment/paypal/confirm',

    GALLERYS: (projectID) => `gallery/list-admin?project_id=${projectID}`,
    GALLERY: `gallery`,
    DELETE_GALLERY: 'gallery/delete',

    PROPERTYS: (offset, query, records) => `property?offset=${offset}&query=${query}&limit=${records}`,
    PROPERTY_WITH_ID: id => `property/${id}`,
    PROPERTY: `property`,
    UPDATE_PROPERTY_DETAILS: 'property/update/details',

    FILES: (projectID) => `project-files/list-admin?project_id=${projectID}`,
    FILE: `project-files`,
    DELETE_FILE: 'project-files/delete',
}
