import React from "react"

import {Card, CardBody, Container} from "reactstrap"

import Breadcrumbs from "../../components/Common/Breadcrumb"
import MetaTitle from "../../components/MetaTitle"
import TransactionsTable from "../../components/Tables/TransactionsTable";

const Transactions = props => {
    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTitle title="Transactions"/>
                <Container fluid={true}>
                    <Breadcrumbs title="Dashboard" breadcrumbItem="Transactions" {...props}/>
                    <Card>
                        <CardBody>

                           <TransactionsTable/>

                        </CardBody>
                    </Card>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default Transactions
