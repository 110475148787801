import {FormAlert} from "../../../components/FormAlert";
import {Col, Row} from "reactstrap";
import TextField from "../../../components/TextField";
import {CountriesSelect} from "../../../components/CountriesSelect";
import SelectView from "../../../components/SelectView";
import {TextEditor} from "../../../components/TextEditor";
import DatePicker from "../../../components/DatePicker";
import ProgressView from "../../../components/ProgressView";
import TamashaButton from "../../../components/TamashaButton";
import React, {useEffect} from "react";
import {useProjects} from "../../../hooks/useProjects";
import {useArchitects} from "../../../hooks/useArchitects";
import {useCategories} from "../../../hooks/useCategories";
import {useGroups} from "../../../hooks/useGroups";
import {useSubCategories} from "../../../hooks/useSubCategories";
import {useMaterials} from "../../../hooks/useMaterials";
import {useManufacturers} from "../../../hooks/useManufacturers";
import RichTextEditor from 'react-rte';

export const ProjectForm = ({entityType, project}) => {
    const {
        name,
        setName,
        setCreateAnother,
        createAnother,
        loading,
        error,
        message,
        loadingStates,
        setPhoto,
        createProject,
        updateProjectDetails,
        startDate,
        setStartDate,
        endDate,
        setEndDate,
        description,
        setDescription,
        category,
        setCategory,
        ownerID,
        setOwnerID,
        videoUrl,
        setVideoUrl,
        region,
        setRegion,
        group,
        setGroup,
        setSubCategory,
        setMaterial,
        material,
        setManufacturer,
        manufacturer,
        tags,
        setTags,
        year,
        setYear,
        area,
        setArea,
        color,
        setColor,
        setEntityType,
        propertyId,
        setPropertyId,
        articleType,
        setArticleType,
        use,
        setUse,
        isStudent,
        setIsStudent,
    } = useProjects()


    const {architects, loadArchitects} = useArchitects()
    const {
        categories,
        loadCategories,
    } = useCategories()
    const {loadGroups, groups} = useGroups()
    const {subCategories, loadSubCategories} = useSubCategories()
    const {materials, loadMaterials} = useMaterials()
    const {manufacturers, loadManufacturers} = useManufacturers()

    useEffect(() => {
        loadArchitects(0, '')
        loadGroups(0, '', 24, entityType)
        loadMaterials(0, '')
        loadManufacturers(0, '')
    }, [entityType])

    useEffect(() => {
        if (!group) return

        loadCategories(group, 0, '')
    }, [group])

    useEffect(() => {
        if (!category) return

        loadSubCategories(category, 0, '')
    }, [category])

    useEffect(() => {
        groups.length > 0 && setGroup(groups[0].id)
    }, [groups])

    useEffect(() => {
        materials.length > 0 && setMaterial(materials[0].id)
    }, [materials])

    useEffect(() => {
        manufacturers.length > 0 && setManufacturer(manufacturers[0].id)
    }, [manufacturers])

    useEffect(() => {
        if (categories.length === 0) {
            setCategory('');
            return
        }

        setCategory(categories?.[0].id)
    }, [categories])

    useEffect(() => {
        if (subCategories.length === 0) {
            setSubCategory('');
            return
        }

        setSubCategory(subCategories?.[0].id)
    }, [subCategories])

    useEffect(() => {
        architects?.rows?.length > 0 && setOwnerID(architects?.rows?.[0].id)
    }, [architects?.rows])

    useEffect(() => {
        setEntityType(entityType)
    }, [entityType])

    const isProject = entityType === 'project'

    useEffect(() => {
        if (project) {
            setName(project?.name)
            setRegion(project?.region)
            setGroup(project?.group_id)
            setCategory(project?.category_id)
            setOwnerID(project?.project_owner_id)
            setSubCategory(project?.sub_category_id)
            setYear(project?.year)
            setIsStudent(project?.is_student)
            setMaterial([project?.materials])
            setPropertyId(project?.property_id)
            setManufacturer([project?.manufacturer_id])
            setArea(project?.area_size)
            setUse(project?.use)
            setVideoUrl(project?.video_url)
            setDescription(project?.description)
            setTags(project?.tags)
            setEntityType(project?.entity_type)
        }
    }, [project])

    const handleSubmit = () => {
        if (project) {
            updateProjectDetails(project?.id)
            return
        }

        createProject()
    }

    return (
        <>
            <FormAlert message={message} error={error}/>
            <Row className={"mt-0"}>
                <Col md={12}>
                    <TextField
                        label={"Title"}
                        onChange={e => setName(e.target.value)}
                        value={name}
                    />
                </Col>
                {!project &&
                    <Col md={12}>
                        <TextField
                            type={'file'}
                            label={"Cover photo"}
                            onChange={e => setPhoto(e.target.files[0])}
                        />
                    </Col>

                }

                <Col md={12}>
                    <CountriesSelect
                        value={region}
                        setValue={setRegion}
                    />
                </Col>
                <Col md={4}>
                    <SelectView
                        onChange={e => setGroup(e.target.value)}
                        value={group}
                        label={"Select category group"}
                    >
                        {groups?.map(unit => <option
                            value={unit.id}>{unit.name}</option>)}
                    </SelectView>
                </Col>
                <Col md={4}>
                    <SelectView
                        onChange={e => setCategory(e.target.value)}
                        value={category}
                        label={"Select a category"}
                    >
                        {categories?.map(unit => <option
                            value={unit.id}>{unit.name}</option>)}
                    </SelectView>
                </Col>

                <Col md={4}>
                    <SelectView
                        onChange={e => setCategory(e.target.value)}
                        value={category}
                        label={"Select a sub category"}
                    >
                        {subCategories?.map(unit => <option
                            value={unit.id}>{unit.name}</option>)}
                    </SelectView>
                </Col>

                {isProject &&
                    <Col md={12}>
                        <SelectView
                            onChange={e => {
                                setOwnerID([...e.target.selectedOptions].map(opt => opt.value))
                            }}
                            value={ownerID}
                            label={"Select architect"}
                            multiple
                        >
                            {architects?.rows?.map(unit => <option
                                value={unit.id}>{unit.name}</option>)}
                        </SelectView>
                    </Col>
                }

                {!isProject && (
                    <>
                        <Col md={6}>
                            <SelectView
                                onChange={e => {
                                    setArticleType(e.target.value)
                                }}
                                value={articleType}
                                label={"Select article type"}
                            >
                                <option value={'applied-product'}>Applied Product</option>
                                <option value={'how-to-guide'}>How To Guide</option>
                                <option value={'product'}>Product</option>
                            </SelectView>
                        </Col>
                        <Col md={6}>
                            <SelectView
                                onChange={e => {
                                    setUse(e.target.value)
                                }}
                                value={use}
                                label={"Select use"}
                            >
                                <option value={'bathrooms'}>Bathrooms</option>
                                <option value={'beams-and-columns'}>Beams and Columns</option>
                                <option value={'ceiling'}>Ceiling</option>
                            </SelectView>
                        </Col>
                    </>
                )}

                <Col md={12}>
                    <SelectView
                        onChange={e => {
                            setManufacturer([...e.target.selectedOptions].map(opt => opt.value))
                        }}
                        value={manufacturer}
                        label={"Select manufacturers"}
                        multiple
                    >
                        {manufacturers?.rows?.map(unit => <option
                            value={unit.id}>{unit.name}</option>)}
                    </SelectView>
                </Col>
                <Col md={12}>
                    <SelectView
                        onChange={e => {
                            setMaterial([...e.target.selectedOptions].map(opt => opt.value))
                        }}
                        value={material}
                        label={"Select materials"}
                        multiple
                    >
                        {materials?.map(unit => <option
                            value={unit.id}>{unit.name}</option>)}
                    </SelectView>
                </Col>

                <Col md={12}>
                    <TextEditor
                        defaultValue={project?.description}
                        setValue={setDescription}
                        value={description}
                        label={"Project description"}
                    />
                </Col>


                {isProject &&
                    <>
                        <Col md={4}>
                            <TextField
                                type={"number"}
                                label={"Year"}
                                onChange={e => setYear(e.target.value)}
                                value={year}
                            />
                        </Col>
                        <Col md={4}>
                            <TextField
                                label={"Color code"}
                                onChange={e => setColor(e.target.value)}
                                value={color}
                            />
                        </Col>
                        <Col md={4}>
                            <TextField
                                type={"number"}
                                label={"Area size (in SQM)"}
                                onChange={e => setArea(e.target.value)}
                                value={area}
                            />
                        </Col>

                        <Col md={6}>
                            <DatePicker
                                label={"Start date"}
                                onSelected={e => setStartDate(e)}
                                value={startDate}
                            />
                        </Col>

                        <Col md={6}>
                            <DatePicker
                                label={"End date"}
                                onSelected={e => setEndDate(e)}
                                value={endDate}
                            />
                        </Col>

                        <Col md={12}>
                            <SelectView
                                onChange={e => {
                                    setIsStudent(e.target.value)
                                }}
                                value={isStudent}
                                label={"Is a student project?"}
                            >
                                <option value={1}>Yes</option>
                                <option value={0}>No</option>
                            </SelectView>
                        </Col>
                    </>
                }

                <Col md={12}>
                    <TextField
                        label={"Video url. e.g YouTube or Vimeo url"}
                        onChange={e => setVideoUrl(e.target.value)}
                        value={videoUrl}
                    />
                </Col>

                <Col md={12}>
                    <TextField
                        type="textarea"
                        label={"Tags"}
                        onChange={e => setTags(e.target.value)}
                        value={tags}
                    />
                </Col>


            </Row>

            <hr/>

            <div className={"float-end justify-content-center d-flex flex-row align-items-center"}>
                {!project &&
                    <div className="form-check form-check-end me-2">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            onChange={e => setCreateAnother(!createAnother)}
                            checked={createAnother}
                        />
                        <label className="form-check-label text-muted">
                            Create another
                        </label>
                    </div>
                }

                {(loading[loadingStates.createProject] || loading[loadingStates.updateProject]) ?
                    <ProgressView/> :
                    <TamashaButton
                        extraClassName={"float-end"}
                        onClick={handleSubmit}>Create</TamashaButton>}
            </div>
        </>
    )
}
