import React, {useEffect, useState} from "react"

import {Card, CardBody, Col, Container, Modal, Nav, NavItem, NavLink, Row, TabContent, TabPane} from "reactstrap"

import Breadcrumbs from "../../components/Common/Breadcrumb"
import MetaTitle from "../../components/MetaTitle"
import classnames from "classnames";
import ProgressView from "../../components/ProgressView";
import TamashaButton from "../../components/TamashaButton";
import TextField from "../../components/TextField";
import {FormAlert} from "../../components/FormAlert";
import {PageLoader} from "../../components/PageLoader";
import {useSkills} from "../../hooks/useSkills";
import {DetailsTab} from "./components/DetailsTab";
import SelectView from "../../components/SelectView";
import DatePicker from "../../components/DatePicker";
import FilePicker from "../../components/FilePicker";
import QuestionsTable from "../../components/Tables/QuestionsTable";
import {DeleteButton} from "../../components/DeleteButton";

const styles = {
    tabStyle: {
        cursor: "pointer"
    }
}


const SkillDetail = ({match, location, ...rest}) => {

    const [activeTab, setActiveTab] = useState(0)
    const toggle = tab => setActiveTab(tab)

    const {id} = match.params

    const {
        name,
        setName,
        loading,
        error,
        message,
        loadingStates,
        setPhoto,
        updateSkillDetails,
        updateSkillCover,
        updateSkillStatus,
        findSkill,
        skill,
        description,
        setDescription,
        rewardPoints,
        setRewardPoints,
        backgroundColor,
        setBackgroundColor,
        comesAfter,
        setComesAfter,
        skills,
        loadSkills,
        duration,
        setDuration,
        setQuestions,
        questions,
        customButtonText,
        customButtonLink,
        setCustomButtonLink,
        setCustomButtonText,
        deleteSkill
    } = useSkills()

    useEffect(() => {
        loadSkills(0, '', 24, '', '')
    }, [])

    useEffect(() => {
        findSkill(id)
    }, [id])

    useEffect(() => {
        if (!skill) {
            return
        }

        setName(skill?.title)
        setDescription(skill?.description)
        setRewardPoints(skill?.reward_points)
        setBackgroundColor(skill?.background_color)
        setComesAfter(skill?.comes_after)
        setDuration(skill?.duration)
        setQuestions(JSON.parse(skill?.questions || "[]"))
        setCustomButtonLink(skill?.custom_button_link)
        setCustomButtonText(skill?.custom_button_text)
    }, [skill])


    return (
        <React.Fragment>
            {skill ?
                <div className="page-content">
                    <MetaTitle title={skill.title}/>
                    <Container fluid={true}>
                        <Breadcrumbs title="Dashboard" breadcrumbItem={skill.title} {...rest}/>

                        <Card>
                            <CardBody>

                                <div className={"mb-2 mt-2 d-flex justify-content-end"}>
                                    {loading[loadingStates.updateSkill] ? <ProgressView/> :
                                        <TamashaButton
                                            extraClassName={`${skill?.verified ? "btn-danger" : "btn-success"} mb-2 me-3`}
                                            onClick={() => {
                                                updateSkillStatus(skill?.id, skill.verified ? 0 : 1)
                                            }}>
                                            {skill.verified ? "Deactivate" : "Activate"}
                                        </TamashaButton>}

                                    {loading[loadingStates.deleteSkill] ? <ProgressView/> :
                                        <DeleteButton onDelete={() => deleteSkill(skill?.id)}/>
                                    }
                                </div>

                                <FormAlert message={message} error={error}/>

                                <Nav tabs>
                                    <NavItem>
                                        <NavLink
                                            style={styles.tabStyle}
                                            className={classnames({
                                                active: activeTab === 0,
                                            })}
                                            onClick={() => toggle(0)}
                                        >
                                            Details
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            style={styles.tabStyle}
                                            className={classnames({
                                                active: activeTab === 1,
                                            })}
                                            onClick={() => toggle(1)}
                                        >
                                            Questions
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            style={styles.tabStyle}
                                            className={classnames({
                                                active: activeTab === 2,
                                            })}
                                            onClick={() => toggle(2)}
                                        >
                                            Update Details
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            style={styles.tabStyle}
                                            className={classnames({
                                                active: activeTab === 4,
                                            })}
                                            onClick={() => toggle(4)}
                                        >
                                            Update Background Icon
                                        </NavLink>
                                    </NavItem>
                                </Nav>

                                <TabContent activeTab={activeTab} className="p-3 text-muted">
                                    <TabPane tabId={0}>
                                        <DetailsTab skill={skill}/>
                                    </TabPane>
                                    <TabPane tabId={1}>
                                        <QuestionsTable
                                            rawQuestions={questions || []}
                                            onUpdate={(qstns) => updateSkillDetails(skill?.id, qstns)}/>
                                    </TabPane>

                                    <TabPane tabId={2}>
                                        <Row className={"mt-0"}>

                                            <Col md={12}>
                                                <TextField
                                                    label={"Name"}
                                                    onChange={e => setName(e.target.value)}
                                                    value={name}
                                                />
                                            </Col>
                                            <Col md={12}>
                                                <TextField
                                                    type="textarea"
                                                    label={"Description"}
                                                    onChange={e => setDescription(e.target.value)}
                                                    value={description}
                                                    rows="3"
                                                />
                                            </Col>
                                            <Col md={12}>
                                                <TextField
                                                    label={"Custom button text (optional)"}
                                                    onChange={e => setCustomButtonText(e.target.value)}
                                                    value={customButtonText}
                                                />
                                            </Col>
                                            <Col md={12}>
                                                <TextField
                                                    label={"Custom button link (optional) - Start with https://"}
                                                    onChange={e => setCustomButtonLink(e.target.value)}
                                                    value={customButtonLink}
                                                />
                                            </Col>
                                            <Col md={12}>
                                                <TextField
                                                    label={"Background color code (On Completion)"}
                                                    onChange={e => setBackgroundColor(e.target.value)}
                                                    value={backgroundColor}
                                                />
                                            </Col>
                                            <Col md={12}>
                                                <SelectView
                                                    onChange={e => setComesAfter(e.target.value)}
                                                    value={comesAfter}
                                                    label={"Select a previous skill that will unlock this skill."}
                                                >
                                                    <option value={'first'}>This is the first skill of the quest
                                                    </option>
                                                    {skills.map(s => <option value={s.id}
                                                                             key={s.id}>{s.title}</option>)}
                                                </SelectView>
                                            </Col>
                                            <Col md={6}>
                                                <TextField
                                                    label={"Reward points"}
                                                    onChange={e => setRewardPoints(e.target.value)}
                                                    value={rewardPoints}
                                                />
                                            </Col>
                                            <Col md={6}>
                                                <TextField
                                                    label={"Approximate completion time (e.g 15m)"}
                                                    onChange={e => setDuration(e.target.value)}
                                                    value={duration}
                                                />
                                            </Col>

                                            <Col md={12}>
                                                <div className={"float-end justify-content-end"}>
                                                    {loading[loadingStates.updateSkill] ? <ProgressView/> :
                                                        <TamashaButton
                                                            extraClassName={"float-end"}
                                                            onClick={() => {
                                                                updateSkillDetails(skill?.id, questions)
                                                            }}>Update</TamashaButton>}
                                                </div>
                                            </Col>
                                        </Row>
                                    </TabPane>

                                    <TabPane tabId={4}>
                                        <Row className={"mt-0"}>
                                            <Col md={12}>
                                                <FilePicker
                                                    label={"Icon"}
                                                    onChange={e => setPhoto(e.target.files[0])}
                                                />
                                            </Col>
                                        </Row>

                                        <div className={"float-end justify-content-end"}>
                                            {loading[loadingStates.updateSkill] ? <ProgressView/> :
                                                <TamashaButton
                                                    extraClassName={"float-end"}
                                                    onClick={() => {
                                                        updateSkillCover(skill?.id)
                                                    }}>Update Icon</TamashaButton>}
                                        </div>
                                    </TabPane>

                                </TabContent>

                            </CardBody>
                        </Card>

                    </Container>
                </div>
                : <PageLoader/>
            }

        </React.Fragment>
    )
}

export default SkillDetail
