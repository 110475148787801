import PropTypes from "prop-types"
import React, {useEffect, useRef} from "react"

import SimpleBar from "simplebar-react"

import MetisMenu from "metismenujs"
import {withRouter} from "react-router-dom"
import {Link} from "react-router-dom"

const SidebarContent = props => {
    const ref = useRef()
    // Use ComponentDidMount and ComponentDidUpdate method symultaniously
    useEffect(() => {
        const pathName = props.location.pathname

        const initMenu = () => {
            new MetisMenu("#side-menu")
            let matchingMenuItem = null
            const ul = document.getElementById("side-menu")
            const items = ul.getElementsByTagName("a")
            for (let i = 0; i < items.length; ++i) {
                if (pathName === items[i].pathname) {
                    matchingMenuItem = items[i]
                    break
                }
            }
            if (matchingMenuItem) {
                activateParentDropdown(matchingMenuItem)
            }
        }
        initMenu()
    }, [props.location.pathname])

    useEffect(() => {
        ref.current.recalculate()
    })

    function scrollElement(item) {
        if (item) {
            const currentPosition = item.offsetTop
            if (currentPosition > window.innerHeight) {
                ref.current.getScrollElement().scrollTop = currentPosition - 300
            }
        }
    }

    function activateParentDropdown(item) {
        item.classList.add("active")
        const parent = item.parentElement
        const parent2El = parent.childNodes[1]
        if (parent2El && parent2El.id !== "side-menu") {
            parent2El.classList.add("mm-show")
        }

        if (parent) {
            parent.classList.add("mm-active")
            const parent2 = parent.parentElement

            if (parent2) {
                parent2.classList.add("mm-show") // ul tag

                const parent3 = parent2.parentElement // li tag

                if (parent3) {
                    parent3.classList.add("mm-active") // li
                    parent3.childNodes[0].classList.add("mm-active") //a
                    const parent4 = parent3.parentElement // ul
                    if (parent4) {
                        parent4.classList.add("mm-show") // ul
                        const parent5 = parent4.parentElement
                        if (parent5) {
                            parent5.classList.add("mm-show") // li
                            parent5.childNodes[0].classList.add("mm-active") // a tag
                        }
                    }
                }
            }
            scrollElement(item)
            return false
        }
        scrollElement(item)
        return false
    }


    return (
        <React.Fragment>
            <SimpleBar style={{maxHeight: "100%"}} ref={ref}>
                <div id="sidebar-menu">
                    <ul className="metismenu list-unstyled" id="side-menu">

                        <li>
                            <Link to="/dashboard" className=" waves-effect">
                                <i className="bx bx-home-circle"></i>
                                <span>Dashboard</span>
                            </Link>
                        </li>

                        <li>
                            <Link to="/dashboard/architects" className=" waves-effect">
                                <i className="bx bx-user-pin"></i>
                                <span>Architects</span>
                            </Link>
                        </li>

                        <li>
                            <Link to="/dashboard/manufacturers" className=" waves-effect">
                                <i className="bx bx-building-house"></i>
                                <span>Manufacturers</span>
                            </Link>
                        </li>

                        <li>
                            <Link to="/dashboard/materials" className=" waves-effect">
                                <i className="bx bx-card"></i>
                                <span>Materials</span>
                            </Link>
                        </li>

                         <li>
                            <Link to="/dashboard/properties" className=" waves-effect">
                                <i className="bx bx-badge"></i>
                                <span>Properties</span>
                            </Link>
                        </li>

                        <li>
                            <Link to="/dashboard/category-groups" className=" waves-effect">
                                <i className="bx bx-cog"></i>
                                <span>Categories</span>
                            </Link>
                        </li>

                        <li>
                            <Link to="/dashboard/projects" className=" waves-effect">
                                <i className="bx bx-building"></i>
                                <span>Projects</span>
                            </Link>
                        </li>

                         <li>
                            <Link to="/dashboard/products" className=" waves-effect">
                                <i className="bx bx-list-check"></i>
                                <span>Products</span>
                            </Link>
                        </li>

                        <li>
                            <Link to="/dashboard/accounts" className=" waves-effect">
                                <i className="bx bx-user"></i>
                                <span>Accounts</span>
                            </Link>
                        </li>

                        {/*<li>*/}
                        {/*    <Link to="/dashboard/quests" className=" waves-effect">*/}
                        {/*        <i className="fa fa-shield-alt"></i>*/}
                        {/*        <span>Quests</span>*/}
                        {/*    </Link>*/}
                        {/*</li>*/}
                        {/*<li>*/}
                        {/*    <Link to="/dashboard/transactions" className=" waves-effect">*/}
                        {/*        <i className="fa fa-receipt"></i>*/}
                        {/*        <span>Transactions</span>*/}
                        {/*    </Link>*/}
                        {/*</li>*/}
                    </ul>
                </div>
            </SimpleBar>
        </React.Fragment>
    )
}

SidebarContent.propTypes = {
    location: PropTypes.object,
}

export default withRouter(SidebarContent)
