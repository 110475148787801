import React from "react"

import {Card, CardBody, Container} from "reactstrap"

import Breadcrumbs from "../../components/Common/Breadcrumb"
import MetaTitle from "../../components/MetaTitle"
import FormContainer from "../../components/FormContainer";
import {ProjectForm} from "./components/ProjectForm";

const NewProject = props => {

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTitle title={"Create a project"}/>
                <Container fluid={true}>
                    <Breadcrumbs title={"Dashboard"} breadcrumbItem={"Create a project"} {...props}/>

                    <FormContainer>
                        <Card>
                            <CardBody>
                                <ProjectForm entityType={'project'}/>
                            </CardBody>
                        </Card>
                    </FormContainer>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default NewProject
