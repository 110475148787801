import {useState} from "react";
import {fetchData, postData} from "../util/Server";
import Endpoints from "../util/Endpoints";
import Strings from "../util/Strings";
import {loadingState} from "../util/Util";

const loadingStates = {
    manufacturers: 'manufacturers',
    createManufacturer: 'createManufacturer',
    updateManufacturer: 'updateManufacturer',
    deleteManufacturer: 'deleteManufacturer',
}

export const useManufacturers = () => {
    const [manufacturers, setManufacturers] = useState([])
    const [loading, setIsLoading] = useState({})
    const [error, setError] = useState('')
    const [message, setMessage] = useState('')

    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [photo, setPhoto] = useState('')
    const [coverPhoto, setCoverPhoto] = useState('')
    const [website, setWebsite] = useState('')
    const [overview, setOverview] = useState('')
    const [about, setAbout] = useState('')

    const [createAnother, setCreateAnother] = useState(false)

    const loadManufacturers = (offset, query, records = Strings.RECORDS_PER_PAGE) => {
        setIsLoading(loadingState(loading, loadingStates.manufacturers, true))

        fetchData(Endpoints.MANUFACTURERS(offset, query, records))
            .then(res => res.json())
            .then(res => {
                setManufacturers(res.data || {})
            }).catch(e => {
            setError(Strings.CONNECTION_ERROR_MESSAGE)
        })
            .finally(() => setIsLoading(loadingState(loading, loadingStates.manufacturers, false)))
    }

    const createManufacturer = () => {
        setError('')
        setMessage('')

        if (!name || !email || !phoneNumber || !overview || !about) {
            setError('All fields are required')
            return
        }

        setIsLoading(loadingState(loading, loadingStates.createManufacturer, true))

        const data = new FormData()
        data.append("photo", photo)
        data.append("cover", coverPhoto)
        data.append("name", name)
        data.append("email", email)
        data.append("phone_number", phoneNumber)
        data.append("website", website)
        data.append("overview", overview)
        data.append("about", about)

        postData(Endpoints.MANUFACTURER, data)
            .then(res => res.json())
            .then(res => {
                if (!res.success) {
                    setError(res.errors?.[0] || res.message)
                    return;
                }

                setMessage(res.data)

                setName('')
                setEmail('')
                setPhoneNumber('')

                setTimeout(() => {
                    setMessage('')
                    setError('')
                    !createAnother && window.location.assign("/dashboard/manufacturers")
                }, 1500)


            }).catch(e => {
            setError(Strings.CONNECTION_ERROR_MESSAGE)
        })
            .finally(() => setIsLoading(loadingState(loading, loadingStates.createManufacturer, false)))
    }

    const updateManufacturerDetails = (id) => {
        setError('')
        setMessage('')

        if (!name || !email || !phoneNumber || !overview || !about) {
            setError('All fields are required')
            return
        }

        setIsLoading(loadingState(loading, loadingStates.updateManufacturer, true))

        const data = new FormData()
        data.append("id", id)
        data.append("name", name)
        data.append("email", email)
        data.append("phone_number", phoneNumber)
        data.append("website", website)
        data.append("overview", overview)
        data.append("about", about)

        postData(Endpoints.UPDATE_MANUFACTURER_DETAILS, data)
            .then(res => res.json())
            .then(res => {
                if (!res.success) {
                    setError(res.errors?.[0] || res.message)
                    return;
                }

                setMessage(res.data)

                setTimeout(() => {
                    setMessage('')
                    setError('')
                }, 1500)

            }).catch(e => {
            setError(Strings.CONNECTION_ERROR_MESSAGE)
        })
            .finally(() => setIsLoading(loadingState(loading, loadingStates.updateManufacturer, false)))
    }

    const updateManufacturerPhoto = (id) => {
        setError('')
        setMessage('')

        if (!photo) {
            setError('All fields are required')
            return
        }

        setIsLoading(loadingState(loading, loadingStates.updateManufacturer, true))

        const data = new FormData()
        data.append("id", id)
        data.append("photo", photo)

        postData(Endpoints.UPDATE_MANUFACTURER_PHOTO, data)
            .then(res => res.json())
            .then(res => {
                if (!res.success) {
                    setError(res.errors?.[0] || res.message)
                    return;
                }

                setMessage(res.data)

                setTimeout(() => {
                    setMessage('')
                    setError('')
                }, 1500)

            }).catch(e => {
            setError(Strings.CONNECTION_ERROR_MESSAGE)
        })
            .finally(() => setIsLoading(loadingState(loading, loadingStates.updateManufacturer, false)))
    }

    const updateManufacturerCover = (id) => {
        setError('')
        setMessage('')

        if (!coverPhoto) {
            setError('All fields are required')
            return
        }

        setIsLoading(loadingState(loading, loadingStates.updateManufacturer, true))

        const data = new FormData()
        data.append("id", id)
        data.append("cover", coverPhoto)

        postData(Endpoints.UPDATE_MANUFACTURER_COVER, data)
            .then(res => res.json())
            .then(res => {
                if (!res.success) {
                    setError(res.errors?.[0] || res.message)
                    return;
                }

                setMessage(res.data)

                setTimeout(() => {
                    setMessage('')
                    setError('')
                }, 1500)

            }).catch(e => {
            setError(Strings.CONNECTION_ERROR_MESSAGE)
        })
            .finally(() => setIsLoading(loadingState(loading, loadingStates.updateManufacturer, false)))
    }

    return {
        manufacturers,
        loading,
        error,
        loadManufacturers,
        createManufacturer,
        createAnother,
        setCreateAnother,
        message,
        setMessage,
        loadingStates,
        email,
        setEmail,
        phoneNumber,
        setPhoneNumber,
        name,
        setName,
        photo,
        setPhoto,
        coverPhoto,
        setCoverPhoto,
        website,
        setWebsite,
        overview,
        setOverview,
        about,
        setAbout,
        updateManufacturerPhoto,
        updateManufacturerCover,
        updateManufacturerDetails,
    }

}
