import React, {useEffect, useState} from "react"

import {Card, CardBody, Col, Container, Modal, Nav, NavItem, NavLink, Row, TabContent, TabPane} from "reactstrap"

import Breadcrumbs from "../../components/Common/Breadcrumb"
import MetaTitle from "../../components/MetaTitle"
import classnames from "classnames";
import ProgressView from "../../components/ProgressView";
import TamashaButton from "../../components/TamashaButton";
import TextField from "../../components/TextField";
import {FormAlert} from "../../components/FormAlert";
import {PageLoader} from "../../components/PageLoader";
import {useQuests} from "../../hooks/useQuests";
import {DetailsTab} from "./components/DetailsTab";
import SelectView from "../../components/SelectView";
import DatePicker from "../../components/DatePicker";
import FilePicker from "../../components/FilePicker";
import Skills from "../Skills/Skills";
import {DeleteButton} from "../../components/DeleteButton";

const styles = {
    tabStyle: {
        cursor: "pointer"
    }
}


const QuestDetail = ({match, location, ...rest}) => {

    const [activeTab, setActiveTab] = useState(0)
    const toggle = tab => setActiveTab(tab)

    const [showDeleteDialog, setShowDeleteDialog] = useState(false)

    const {id} = match.params

    const {
        name,
        setName,
        loading,
        error,
        message,
        loadingStates,
        setPhoto,
        updateQuestDetails,
        updateQuestCover,
        updateQuestStatus,
        findQuest,
        quest,
        deadline,
        setDeadline,
        description,
        setDescription,
        hasDeadline,
        setHasDeadline,
        isAppManaged,
        setIsAppManaged,
        rewardPoints,
        setRewardPoints,
        appID,
        setAppID,
        emails,
        setEmails,
        deleteQuest
    } = useQuests()

    useEffect(() => {
        findQuest(id)
    }, [id])

    useEffect(() => {
        if (!quest) {
            return
        }

        setName(quest?.title)
        setDescription(quest?.description)
        setHasDeadline(quest?.has_deadline ? 1 : 0)
        setIsAppManaged(quest?.is_app_managed ? 1 : 0)
        setRewardPoints(quest?.reward_points)
        setAppID(quest?.app_id)
        setEmails(quest?.emails)
    }, [quest])


    return (
        <React.Fragment>
            {quest ?
                <div className="page-content">
                    <MetaTitle title={quest.title}/>
                    <Container fluid={true}>
                        <Breadcrumbs title="Dashboard" breadcrumbItem={quest.title} {...rest}/>


                        <Card>
                            <CardBody>

                                <div className={"mb-2 mt-2 d-flex justify-content-end"}>
                                    {loading[loadingStates.updateQuest] ? <ProgressView/> :
                                        <TamashaButton
                                            extraClassName={`${quest?.verified ? "btn-danger" : "btn-success"} mb-2 me-3`}
                                            onClick={() => {
                                                updateQuestStatus(quest?.id, quest.verified ? 0 : 1)
                                            }}>
                                            {quest.verified ? "Deactivate" : "Activate"}
                                        </TamashaButton>}

                                    {loading[loadingStates.deleteQuest] ? <ProgressView/> :
                                        <DeleteButton onDelete={() => deleteQuest(quest?.id)}/>
                                    }
                                </div>

                                <FormAlert message={message} error={error}/>

                                <Nav tabs>
                                    <NavItem>
                                        <NavLink
                                            style={styles.tabStyle}
                                            className={classnames({
                                                active: activeTab === 0,
                                            })}
                                            onClick={() => toggle(0)}
                                        >
                                            Details
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            disabled={quest?.is_app_managed}
                                            style={styles.tabStyle}
                                            className={classnames({
                                                active: activeTab === 1,
                                            })}
                                            onClick={() => toggle(1)}
                                        >
                                            Skills
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            style={styles.tabStyle}
                                            className={classnames({
                                                active: activeTab === 2,
                                            })}
                                            onClick={() => toggle(2)}
                                        >
                                            Update Details
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            style={styles.tabStyle}
                                            className={classnames({
                                                active: activeTab === 5,
                                            })}
                                            onClick={() => toggle(5)}
                                        >
                                            Update Deadline
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            style={styles.tabStyle}
                                            className={classnames({
                                                active: activeTab === 4,
                                            })}
                                            onClick={() => toggle(4)}
                                        >
                                            Update Cover Photo
                                        </NavLink>
                                    </NavItem>
                                </Nav>

                                <TabContent activeTab={activeTab} className="p-3 text-muted">
                                    <TabPane tabId={0}>
                                        <DetailsTab quest={quest}/>
                                    </TabPane>
                                    <TabPane tabId={1}>
                                        <Skills questId={quest.id}/>
                                    </TabPane>

                                    <TabPane tabId={2}>
                                        <Row className={"mt-0"}>
                                            <Col md={12}>
                                                <TextField
                                                    label={"Name"}
                                                    onChange={e => setName(e.target.value)}
                                                    value={name}
                                                />
                                            </Col>
                                            <Col md={12}>
                                                <TextField
                                                    type="textarea"
                                                    label={"Description"}
                                                    onChange={e => setDescription(e.target.value)}
                                                    value={description}
                                                    rows="3"
                                                />
                                            </Col>

                                            <Col md={12}>
                                                <SelectView
                                                    onChange={e => setIsAppManaged(e.target.value)}
                                                    value={isAppManaged}
                                                    label={"Is this quest managed within the mobile application or from admin? Quests managed from the application will need a custom UI and logic for them to be displayed, while those managed from admin, can easily be modified any time."}
                                                >
                                                    <option value={0}>Managed from admin console (Can add skills)
                                                    </option>
                                                    <option value={1}>Managed from mobile application</option>
                                                </SelectView>
                                            </Col>
                                            {(parseInt(isAppManaged) === 1) &&
                                            <>
                                                <Col md={12}>
                                                    <TextField
                                                        label={"Unique app ID. This enables the app to load the correct component for this quest."}
                                                        onChange={e => setAppID(e.target.value)}
                                                        value={appID}
                                                    />
                                                </Col>
                                            </>
                                            }

                                            <Col md={12}>
                                                <TextField
                                                    type={"number"}
                                                    label={"Total reward points"}
                                                    onChange={e => setRewardPoints(e.target.value)}
                                                    value={rewardPoints}
                                                />
                                            </Col>

                                            <Col md={12}>
                                                <TextField
                                                    label={"Email visibility (Leave blank for everyone, and separate emails with commas for multiple emails.)"}
                                                    onChange={e => setEmails(e.target.value)}
                                                    value={emails}
                                                />
                                            </Col>

                                            <Col md={12}>
                                                <div className={"float-end justify-content-end"}>
                                                    {loading[loadingStates.updateQuest] ? <ProgressView/> :
                                                        <TamashaButton
                                                            extraClassName={"float-end"}
                                                            onClick={() => {
                                                                updateQuestDetails(quest?.id)
                                                            }}>Update</TamashaButton>}
                                                </div>
                                            </Col>
                                        </Row>
                                    </TabPane>

                                    <TabPane tabId={5}>
                                        <Row className={"mt-0"}>
                                            <Col md={12}>
                                                <SelectView
                                                    onChange={e => setHasDeadline(e.target.value)}
                                                    value={hasDeadline}
                                                    label={"Does this quest have a deadline?"}
                                                >
                                                    <option value={0}>No, there's no deadline limitation</option>
                                                    <option value={1}>Yes, there's a deadline limitation</option>
                                                </SelectView>
                                            </Col>

                                            {parseInt(hasDeadline) === 1 &&
                                            <Col md={12}>
                                                <DatePicker
                                                    label={"Quest Deadline"}
                                                    onSelected={e => setDeadline(e)}
                                                    value={deadline}
                                                />
                                            </Col>
                                            }
                                        </Row>

                                        <div className={"float-end justify-content-end"}>
                                            {loading[loadingStates.updateQuest] ? <ProgressView/> :
                                                <TamashaButton
                                                    extraClassName={"float-end"}
                                                    onClick={() => {
                                                        updateQuestDetails(quest?.id)
                                                    }}>Update</TamashaButton>}
                                        </div>
                                    </TabPane>

                                    <TabPane tabId={4}>
                                        <Row className={"mt-0"}>
                                            <Col md={12}>
                                                <FilePicker
                                                    label={"Cover photo"}
                                                    onChange={e => setPhoto(e.target.files[0])}
                                                />
                                            </Col>
                                        </Row>

                                        <div className={"float-end justify-content-end"}>
                                            {loading[loadingStates.updateQuest] ? <ProgressView/> :
                                                <TamashaButton
                                                    extraClassName={"float-end"}
                                                    onClick={() => {
                                                        updateQuestCover(quest?.id)
                                                    }}>Update Cover</TamashaButton>}
                                        </div>
                                    </TabPane>

                                </TabContent>

                            </CardBody>
                        </Card>

                    </Container>
                </div>
                : <PageLoader/>
            }

        </React.Fragment>
    )
}

export default QuestDetail
