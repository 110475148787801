import React, {useState} from "react"

import {Card, CardBody, Container, Nav, NavItem, NavLink, TabContent, TabPane} from "reactstrap"

import Breadcrumbs from "../../components/Common/Breadcrumb"
import MetaTitle from "../../components/MetaTitle"
import classnames from "classnames";
import {ChangePassword} from "./ChangePassword";

const styles = {
    tabStyle: {
        cursor: "pointer"
    }
}


const Settings = ({history, ...rest}) => {

    const [activeTab, setActiveTab] = useState(0)
    const toggle = tab => setActiveTab(tab)


    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTitle title={'Settings'}/>
                <Container fluid={true}>
                    <Breadcrumbs title="Dashboard" breadcrumbItem={'Settings'} {...rest}/>

                    <Card>
                        <CardBody>

                            <Nav tabs>
                                <NavItem>
                                    <NavLink
                                        style={styles.tabStyle}
                                        className={classnames({
                                            active: activeTab === 0,
                                        })}
                                        onClick={() => toggle(0)}
                                    >
                                        Change password
                                    </NavLink>
                                </NavItem>
                                {/*<NavItem>*/}
                                {/*    <NavLink*/}
                                {/*        style={{cursor: "pointer"}}*/}
                                {/*        className={classnames({*/}
                                {/*            active: activeTab === 1,*/}
                                {/*        })}*/}
                                {/*        onClick={() => toggle(1)}*/}
                                {/*    >*/}
                                {/*        Change profile information*/}
                                {/*    </NavLink>*/}
                                {/*</NavItem>*/}
                                {/*<NavItem>*/}
                                {/*    <NavLink*/}
                                {/*        style={{cursor: "pointer"}}*/}
                                {/*        className={classnames({*/}
                                {/*            active: activeTab === 2,*/}
                                {/*        })}*/}
                                {/*        onClick={() => toggle(2)}*/}
                                {/*    >*/}
                                {/*        Change profile picture*/}
                                {/*    </NavLink>*/}
                                {/*</NavItem>*/}
                            </Nav>

                            <TabContent activeTab={activeTab} className="p-3 text-muted">
                                <TabPane tabId={0}>
                                    <ChangePassword history={history}/>
                                </TabPane>
                                <TabPane tabId={1}>

                                </TabPane>
                            </TabContent>

                        </CardBody>
                    </Card>

                </Container>
            </div>
        </React.Fragment>
    )
}

export default Settings
