import React, {useEffect, useMemo, useState} from "react"
import {Table, Tbody, Td, Th, Thead, Tr} from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import ProgressView from "../ProgressView"
import useDebounce from "../../hooks/useDebounce";
import SearchAndFilter from "../SearchAndFilter";
import {useTransactions} from "../../hooks/useTransactions";
import {getFormarttedNumber} from "../../util/Util";
import {Link} from "react-router-dom";
import moment from "moment";
import {Col, Row} from "reactstrap";
import DatePicker from "../DatePicker";
import TimePicker from "../TimePicker";
import SelectView from "../SelectView";
import Pagination from "../Pagination";
import {CSVLink} from "react-csv";
import Strings from "../../util/Strings";


const TransactionsTable = ({projectId = ""}) => {

    const {loadTransactions, transactions, loading, loadingStates} = useTransactions()
    const [page, setPage] = useState(1)
    const [query, setQuery] = useState('')
    const [records, setRecords] = useState(Strings.RECORDS_PER_PAGE)
    const [startDate, setStartDate] = useState('')
    const [startTime, setStartTime] = useState('')
    const [endDate, setEndDate] = useState('')
    const [endTime, setEndTime] = useState('')
    const [paymentStatus, setPaymentStatus] = useState('')

    const debouncedQuery = useDebounce(query, 500)

    useEffect(() => {
        if (debouncedQuery && page > 1) {
            setPage(1)
            return
        }

        loadTransactions(projectId, page, debouncedQuery, records, startDate, startTime, endDate, endTime, paymentStatus)
    }, [page, debouncedQuery, records, startDate, startTime, endDate, endTime, paymentStatus])


    useEffect(() => {
        setStartDate(moment().subtract(7, 'months').format("DD/MM/YYYY"))
        setStartTime(moment().subtract(7, 'months').format("HH:mm"))
        setEndDate(moment().format("DD/MM/YYYY"))
        setEndTime(moment().format("HH:mm"))
    }, [])

    useEffect(() => {
        setPage(transactions?.page)
    }, [transactions?.page])

    const csvData = useMemo(() => {
        return transactions?.rows?.map(field => {
            const newField = {...field}
            delete newField.user_details
            delete newField.project_details

            return newField
        })
    }, [transactions?.rows])


    return <>

        <SearchAndFilter onQuery={query => setQuery(query)} onRecords={records => setRecords(records)}>

            <Col md={2}>
                <SelectView label={"Payment status"}
                            onChange={e => setPaymentStatus(e.target.value)}
                            value={paymentStatus}
                >
                    <option value={""}>All</option>
                    <option value={'PENDING'}>Pending</option>
                    <option value={'COMPLETED'}>Completed</option>
                    <option value={'CANCELLED'}>Cancelled</option>
                </SelectView>
            </Col>

            <Col lg={2} md={6} sm={6}>
                <DatePicker
                    onSelected={e => {
                        setStartDate(e)
                    }}
                    value={startDate}
                    label={"Start date"}
                />
            </Col>
            <Col lg={1} md={6} sm={6}>
                <TimePicker
                    onSelected={e => {
                        setStartTime(e)
                    }}
                    value={startTime}
                    label={"Start time"}
                />
            </Col>
            <Col lg={2} md={6} sm={6}>
                <DatePicker
                    onSelected={e => {
                        setEndDate(e)
                    }}
                    value={endDate}
                    label={"End date"}
                />
            </Col>
            <Col lg={1} md={6} sm={6}>
                <TimePicker
                    onSelected={e => {
                        setEndTime(e)
                    }}
                    value={endTime}
                    label={"End time"}
                />
            </Col>

        </SearchAndFilter>


        {loading[loadingStates.transactions] &&
        <div className={"p-2 text-center"}>
            <ProgressView/>
        </div>
        }

        {!loading[loadingStates.transactions] && transactions?.rows?.length === 0 &&
        <div className={"p-2 text-center"}>
            <p className="mb-2 text-muted">No data available</p>
        </div>
        }

        {transactions?.rows?.length > 0 &&
        <div>
            <Row className="mb-3">

                <Col lg={2} md={6} sm={6}>
                    <CSVLink
                        data={csvData}
                        filename={`Transactions-Page-${page}.csv`}
                        className="btn btn-dark"
                        target="_blank"
                    >
                        Export to CSV
                    </CSVLink>
                </Col>

            </Row>

            <hr/>

            <div className="table-rep-plugin">
                <div
                    className="table-responsive mb-0"
                    data-pattern="priority-columns"
                >
                    <Table className="table table-striped table-bordered">
                        <Thead>
                            <Tr>
                                <Th data-priority="1">Project Name</Th>
                                <Th data-priority="1">User</Th>
                                <Th data-priority="1">User Email</Th>
                                <Th data-priority="1">Amount</Th>
                                <Th data-priority="1">Status</Th>
                                <Th data-priority="1">Processor</Th>
                                <Th data-priority="1">ID</Th>
                                <Th data-priority="1">Created Timestamp</Th>
                                <Th data-priority="1">Completed Timestamp</Th>
                                <Th data-priority="1">Cancelled Timestamp</Th>
                                <Th data-priority="1">Refunded Timestamp</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {transactions?.rows?.map((transaction, b) =>
                                <Tr key={b.toString()}>
                                    <Td>
                                        <Link to={{
                                            pathname: "/dashboard/projects/" + transaction.project_id,
                                            state: {project: transaction.project_details}
                                        }}>
                                            {transaction.project_details?.name}
                                        </Link>
                                    </Td>
                                    <Td>
                                        <Link to={{
                                            pathname: "/dashboard/projects/" + transaction.user_id,
                                            state: {account: transaction.user_details}
                                        }}>
                                            {transaction.user_details?.first_name} {transaction.user_details?.last_name}
                                        </Link>
                                    </Td>
                                    <Td>
                                        {transaction.user_details?.email}
                                    </Td>
                                    <Td>{transaction.currency} {getFormarttedNumber(transaction.amount)}</Td>
                                    <Td>{transaction.status.toUpperCase()}</Td>
                                    <Td>
                                        {transaction.processor.toUpperCase()}
                                    </Td>
                                    <Td>
                                        {transaction.processor_transaction_id}
                                    </Td>
                                    <Td>
                                        {transaction.created_at_string}
                                    </Td>
                                    <Td>
                                        {transaction.status.toUpperCase() === 'COMPLETED' ? transaction.updated_at_string : ''}
                                    </Td>
                                    <Td>
                                        {transaction.status.toUpperCase() === 'CANCELLED' ? transaction.updated_at_string : ''}
                                    </Td>
                                    <Td>
                                        {transaction.status.toUpperCase() === 'REFUNDED' ? transaction.updated_at_string : ''}
                                    </Td>
                                </Tr>
                            )}
                        </Tbody>
                    </Table>

                    <Pagination
                        currentPage={page}
                        numberOfPages={transactions.total_pages}
                        onPageSelected={(page) => {
                            loadTransactions(projectId, page, debouncedQuery, records, startDate, startTime, endDate, endTime, paymentStatus)
                        }}/>

                </div>
            </div>
        </div>
        }
    </>

}

export default TransactionsTable
