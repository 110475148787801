import Strings from './Strings';


export const goToPage = (history, page) => {
    history.push(`${process.env.PUBLIC_URL}${page}`);
}

export const updateDocumentTitle = title => document.title = title + " | " + Strings.APP_DESCRIPTION;

export const validURL = (str) => {
    let pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
    return !!pattern.test(str);
}

export const checkString = s => s==="" ? "-" : s

export const getHost = () => {
    let host = window.location.hostname
    let protocol = window.location.protocol
    if (host === "localhost") {
        host = host + ":" + window.location.port
    }
    return protocol + "//" + host
}

    export const getSalutation = () => {
        let d = new Date()
        let hour = d.getHours()

        if (hour >= 6 && hour < 12) {
            return 'Good morning'
        } else if (hour > +12 && hour < 18) {
            return 'Good afternoon'
        } else {
            return 'Good evening'
        }
    }