import React from 'react';


export default {
    STORAGE_KEY: 'Tamasha_Estate_Admin_Front_Key',
    APP_ID: 'Tamasha-Front-App',
    CONNECTION_ERROR_MESSAGE: 'Cannot connect at this time',
    APP_DESCRIPTION: "maaktaba Admin Portal",
    GUARD_NAME: "estate",
    RECORDS_PER_PAGE: 10
};
