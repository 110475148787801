import {useState} from "react";
import {fetchData, postData} from "../util/Server";
import Endpoints from "../util/Endpoints";
import Strings from "../util/Strings";
import {loadingState} from "../util/Util";

const loadingStates = {
    materials: 'materials',
    createMaterial: 'createMaterial',
    updateMaterial: 'updateMaterial',
    deleteMaterial: 'deleteMaterial',
}

export const useMaterials = () => {
    const [materials, setMaterials] = useState([])
    const [loading, setIsLoading] = useState({})
    const [error, setError] = useState('')
    const [message, setMessage] = useState('')

    const [name, setName] = useState('')

    const [createAnother, setCreateAnother] = useState(false)

    const loadMaterials = (offset, query, records = 24) => {
        setIsLoading(loadingState(loading, loadingStates.materials, true))

        fetchData(Endpoints.MATERIALS(offset, query, records))
            .then(res => res.json())
            .then(res => {
                setMaterials(res.data || [])
            }).catch(e => {
            setError(Strings.CONNECTION_ERROR_MESSAGE)
        })
            .finally(() => setIsLoading(loadingState(loading, loadingStates.materials, false)))
    }

    const createMaterial = () => {
        setError('')
        setMessage('')

        if (!name) {
            setError('All fields are required')
            return
        }

        setIsLoading(loadingState(loading, loadingStates.createMaterial, true))

        const data = new FormData()
        data.append("name", name)

        postData(Endpoints.MATERIAL, data)
            .then(res => res.json())
            .then(res => {
                if (!res.success) {
                    setError(res.errors?.[0] || res.message)
                    return;
                }

                setMessage(res.data)

                setName('')

                setTimeout(() => {
                    setMessage('')
                    setError('')
                    !createAnother && window.location.assign("/dashboard/materials")
                }, 1500)


            }).catch(e => {
            setError(Strings.CONNECTION_ERROR_MESSAGE)
        })
            .finally(() => setIsLoading(loadingState(loading, loadingStates.createMaterial, false)))
    }

    const updateMaterialDetails = (id) => {
        setError('')
        setMessage('')

        if (!name) {
            setError('All fields are required')
            return
        }

        setIsLoading(loadingState(loading, loadingStates.updateMaterial, true))

        const data = new FormData()
        data.append("id", id)
        data.append("name", name)

        postData(Endpoints.UPDATE_MATERIAL_DETAILS, data)
            .then(res => res.json())
            .then(res => {
                if (!res.success) {
                    setError(res.errors?.[0] || res.message)
                    return;
                }

                setMessage(res.data)

                setTimeout(() => {
                    setMessage('')
                    setError('')

                    window.location.assign("/dashboard/materials")
                }, 1500)

            }).catch(e => {
            setError(Strings.CONNECTION_ERROR_MESSAGE)
        })
            .finally(() => setIsLoading(loadingState(loading, loadingStates.updateMaterial, false)))
    }

    return {
        materials,
        loading,
        error,
        loadMaterials,
        createMaterial,
        createAnother,
        setCreateAnother,
        message,
        setMessage,
        loadingStates,
        name,
        setName,
        updateMaterialDetails
    }

}
