import {Alert} from "reactstrap";
import React from "react";

export const FormAlert = ({error, message}) => <>
    {(error || message) ? (
        <Alert
            color={error ? "danger" : "success"}>{error ? error : message}</Alert>
    ) : null}

</>
