import React from "react"
import TamashaButtonLink from "../../components/TamashaButtonLink";
import CategoriesTable from "../../components/Tables/CategoriesTable";

const Categories = ({group}) => {
    return (
        <React.Fragment>
            <div className="text-end mb-4">
                <TamashaButtonLink to={{pathname: "/dashboard/new-category", state: {group}}} outline>
                    Create a category
                </TamashaButtonLink>
            </div>

            <CategoriesTable groupID={group?.id}/>
        </React.Fragment>
    )
}

export default Categories
