import React from "react"
import SidebarContent from "./SidebarContent"

const Sidebar = props => {
    return (
        <React.Fragment>
            <div className="vertical-menu">
                <div className="h-100" data-simplebar>
                    <div style={{flex: 0.9}}>
                        <SidebarContent/>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Sidebar
