import React, {useEffect, useState} from "react"
import {Alert, Card, CardBody, Col, Container, Row} from "reactstrap"

import {Link, useHistory} from "react-router-dom"
import MetaTitle from "../../components/MetaTitle"
import TextField from "../../components/TextField"
import ProgressView from "../../components/ProgressView"
import Copyright from "../../components/Copyright"
import TamashaButton from "../../components/TamashaButton";
import {postData} from "../../util/Server";
import Endpoints from "../../util/Endpoints";
import Strings from "../../util/Strings";

const ResetPassword = ({history, location}) => {

    const [email, setEmail] = useState('')
    const [code, setResetCode] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState('')
    const [message, setMessage] = useState('')

    useEffect(() => {
        if (!location?.state?.email) {
            history.push("/forgot-password")
            return
        }

        setEmail(location.state.email)
    }, [location])

    const submit = () => {
        setError('')

        if (!code) {
            setError('Code is required')
            return
        }

        setIsLoading(true)

        const data = new FormData()
        data.append("email", email)
        data.append("reset_code", code)

        postData(Endpoints.VALIDATE_RESET_CODE, JSON.stringify({email, reset_code: code}))
            .then(res => res.json())
            .then(res => {
                setIsLoading(false)
                if (res.success) {
                    history.push("/update-password", {email, code})
                    return;
                }

                setError(res.message)

            }).catch(e => {
            setIsLoading(false)
            setError(Strings.CONNECTION_ERROR_MESSAGE)
        })


    }

    return (
        <React.Fragment>
            <MetaTitle title={"Reset password"}/>
            <div className="home-btn d-none d-sm-block">
                <Link to="/" className="text-dark">
                    <i className="fas fa-home h2"/>
                </Link>
            </div>
            <div className="account-pages my-5 pt-sm-5">
                <Container>
                    <Row className="justify-content-center">
                        <Col md={8} lg={6} xl={5}>
                            <Card className="overflow-hidden">
                                <div className="bg-primary bg-soft">
                                    <Row>
                                        <Col xs={12}>
                                            <div className="p-4 text-primary">
                                                <h5 className="text-primary">Reset your password</h5>
                                                <span>Enter the reset code sent to your email address (<strong>{email}</strong>)</span>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                                <CardBody className="pt-2">
                                    <div className="p-2">

                                        {(error || message) ? (
                                            <Alert
                                                color={error ? "danger" : "success"}>{error ? error : message}</Alert>
                                        ) : null}

                                        <TextField className="form-control"
                                                   placeholder={"Your reset code"}
                                                   onChange={e => setResetCode(e.target.value)}
                                                   value={code}
                                                   label={"Reset code"}/>


                                        <div className="mt-3 d-grid">
                                            {isLoading ?
                                                <div className={"text-center"}>
                                                    <ProgressView/>
                                                </div>
                                                :
                                                <TamashaButton
                                                    type="submit"
                                                    onClick={submit}
                                                >
                                                    Continue
                                                </TamashaButton>
                                            }
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                            <div className="mt-4 text-center">
                                <p>
                                    <Link
                                        to="/forgot-password"
                                        className="fw-medium text-dark text-decoration-underline"
                                    >
                                        {" "}
                                        Change email address
                                    </Link>{" "}
                                </p>
                                <Copyright/>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}


export default ResetPassword
