import React, {useEffect, useState} from "react"
import {Table, Tbody, Td, Th, Thead, Tr} from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import ProgressView from "../ProgressView"
import TamashaButtonLink from "../TamashaButtonLink";
import CustomPagination from "../Pagination";
import useDebounce from "../../hooks/useDebounce";
import {useCategories} from "../../hooks/useCategories";

const CategoriesTable = ({groupID}) => {

    const {loadCategories, categories, loading, loadingStates} = useCategories()
    const [page, setPage] = useState(1)
    const [query, setQuery] = useState('')
    const [records, setRecords] = useState(10)

    const debouncedQuery = useDebounce(query, 500)

    useEffect(() => {
        loadCategories(groupID, 0, debouncedQuery, records)
    }, [groupID, page, debouncedQuery, records])


    return <>

        {/*<SearchAndFilter onQuery={query => setQuery(query)} onRecords={records => setRecords(records)}/>*/}

        {loading[loadingStates.categories] &&
        <div className={"p-2 text-center"}>
            <ProgressView/>
        </div>
        }

        {!loading[loadingStates.categories] && categories.length === 0 &&
        <div className={"p-2 text-center"}>
            <p className="mb-2 text-muted">No data available</p>
        </div>
        }

        {categories.length > 0 &&
        <div className="mt-3">

            <div className="table-rep-plugin">
                <div
                    className="table-responsive mb-0"
                    data-pattern="priority-columns"
                >
                    <Table className="table table-striped table-bordered">
                        <Thead>
                            <Tr>
                                <Th data-priority="1">Name</Th>
                                <Th data-priority="1">No Of Projects</Th>
                                <Th data-priority="4"></Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {categories.map((category, b) =>
                                <Tr key={b.toString()}>
                                    <Td>{category.name}</Td>
                                    <Td>{category.number_of_projects}</Td>
                                    <Td>
                                        <TamashaButtonLink
                                            to={{pathname: "/dashboard/categories/" + category.id, state: {category}}}
                                            outline={true}
                                            color="primary"
                                            extraClassName={'btn-sm btn-rounded'}>
                                            Details
                                        </TamashaButtonLink>
                                    </Td>
                                </Tr>
                            )}
                        </Tbody>
                    </Table>

                    {!debouncedQuery &&
                    <CustomPagination
                        onPrevious={() => setPage(categories.length - records)}
                        onNext={() => setPage(categories.length)}
                    />
                    }

                </div>
            </div>
        </div>
        }
    </>

}

export default CategoriesTable
