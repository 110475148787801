import React, {useEffect, useState} from "react"
import {Table, Tbody, Td, Th, Thead, Tr} from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import ProgressView from "../ProgressView"
import TamashaButtonLink from "../TamashaButtonLink";
import CustomPagination from "../Pagination";
import useDebounce from "../../hooks/useDebounce";
import SearchAndFilter from "../SearchAndFilter";
import {useAccounts} from "../../hooks/useAccounts";
import Pagination from "../Pagination";
import Strings from "../../util/Strings";

const AccountsTable = () => {

    const {loadAccounts, accounts, loading, loadingStates} = useAccounts()
    const [page, setPage] = useState(1)
    const [query, setQuery] = useState('')
    const [records, setRecords] = useState(Strings.RECORDS_PER_PAGE)

    const debouncedQuery = useDebounce(query, 500)

    useEffect(() => {
        if (debouncedQuery && page > 1) {
            setPage(1)
            return
        }

        loadAccounts(page, debouncedQuery, records)
    }, [page, debouncedQuery, records])

      useEffect(() => {
        setPage(accounts?.page)
    }, [accounts?.page])

    return <>

        <SearchAndFilter onQuery={query => setQuery(query)} onRecords={records => setRecords(records)}/>

        {loading[loadingStates.accounts] &&
        <div className={"p-2 text-center"}>
            <ProgressView/>
        </div>
        }

        {!loading[loadingStates.accounts] && accounts?.rows?.length === 0 &&
        <div className={"p-2 text-center"}>
            <p className="mb-2 text-muted">No data available</p>
        </div>
        }

        {accounts?.rows?.length > 0 &&
        <div className="mt-3">

            <div className="table-rep-plugin">
                <div
                    className="table-responsive mb-0"
                    data-pattern="priority-columns"
                >
                    <Table className="table table-striped table-bordered">
                        <Thead>
                            <Tr>
                                <Th data-priority="1">Name</Th>
                                <Th data-priority="1">Email</Th>
                                <Th data-priority="1">Phone Number</Th>
                                <Th data-priority="1">Registration Step</Th>
                                <Th data-priority="4"></Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {accounts?.rows?.map((account, b) =>
                                <Tr key={b.toString()}>
                                    <Td>{account.first_name} {account.last_name}</Td>
                                    <Td>{account.email}</Td>
                                    <Td>{account.phone_number}</Td>
                                    <Td>{account.next_step}</Td>
                                    <Td>
                                        <TamashaButtonLink
                                            to={{pathname: "/dashboard/accounts/" + account.id, state: {account}}}
                                            outline={true}
                                            color="primary"
                                            extraClassName={'btn-sm btn-rounded'}>
                                            Details
                                        </TamashaButtonLink>
                                    </Td>
                                </Tr>
                            )}
                        </Tbody>
                    </Table>

                    <Pagination
                        currentPage={page}
                        numberOfPages={accounts.total_pages}
                        onPageSelected={(page) => {
                            loadAccounts(page, debouncedQuery, records)
                        }}/>

                </div>
            </div>
        </div>
        }
    </>

}

export default AccountsTable
