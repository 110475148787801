import {useState} from "react";
import {fetchData, postData} from "../util/Server";
import Endpoints from "../util/Endpoints";
import Strings from "../util/Strings";
import {loadingState} from "../util/Util";

const loadingStates = {
    accounts: 'accounts',
    findAccount: 'findAccount',
    createAccount: 'createAccount',
    updateAccount: 'updateAccount',
    deleteAccount: 'deleteAccount',
}

export const useAccounts = () => {
    const [accounts, setAccounts] = useState([])
    const [loading, setIsLoading] = useState({})
    const [error, setError] = useState('')
    const [message, setMessage] = useState('')

    const [account, setAccount] = useState('')

    const [createAnother, setCreateAnother] = useState(false)

    const loadAccounts = (offset, query, records =  Strings.RECORDS_PER_PAGE) => {
        setIsLoading(loadingState(loading, loadingStates.accounts, true))

        fetchData(Endpoints.ACCOUNTS(offset, query, records))
            .then(res => res.json())
            .then(res => {
                setAccounts(res.data || {})
            }).catch(e => {
            setError(Strings.CONNECTION_ERROR_MESSAGE)
        })
            .finally(() => setIsLoading(loadingState(loading, loadingStates.accounts, false)))
    }

    const findAccount = (id) => {
        setIsLoading(loadingState(loading, loadingStates.findAccount, true))

        fetchData(Endpoints.ACCOUNT_WITH_ID(id))
            .then(res => res.json())
            .then(res => {
                setAccount(res.data)
            }).catch(e => {
            setError(Strings.CONNECTION_ERROR_MESSAGE)
        })
            .finally(() => setIsLoading(loadingState(loading, loadingStates.findAccount, false)))
    }

    const updateAccountstatus = (id, verified) => {
        setError('')
        setMessage('')


        setIsLoading(loadingState(loading, loadingStates.updateAccount, true))

        const data = new FormData()
        data.append("id", id)
        data.append("status", verified)

        postData(Endpoints.UPDATE_ACCOUNT_STATUS, data)
            .then(res => res.json())
            .then(res => {
                if (!res.success) {
                    setError(res.errors?.[0] || res.message)
                    return;
                }

                setMessage(res.data)

                findAccount(id)

                setTimeout(() => {
                    setMessage('')
                    setError('')
                }, 1500)

            }).catch(e => {
            setError(Strings.CONNECTION_ERROR_MESSAGE)
        })
            .finally(() => setIsLoading(loadingState(loading, loadingStates.updateAccount, false)))
    }

    return {
        accounts,
        loading,
        error,
        loadAccounts,
        createAnother,
        setCreateAnother,
        message,
        setMessage,
        loadingStates,
        findAccount,
        updateAccountstatus,
        account,
    }

}
