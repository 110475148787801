import {useState} from "react";
import {fetchData, postData} from "../util/Server";
import Endpoints from "../util/Endpoints";
import Strings from "../util/Strings";
import {loadingState} from "../util/Util";

const loadingStates = {
    gallerys: 'gallerys',
    createGallery: 'createGallery',
    updateGallery: 'updateGallery',
    deleteGallery: 'deleteGallery',
}

export const useGallerys = () => {
    const [gallerys, setGallerys] = useState([])
    const [loading, setIsLoading] = useState({})
    const [error, setError] = useState('')
    const [message, setMessage] = useState('')

    const [photo, setPhoto] = useState('')

    const [createAnother, setCreateAnother] = useState(false)

    const loadGallerys = ({offset, query, records = Strings.RECORDS_PER_PAGE, project_id}) => {
        setIsLoading(loadingState(loading, loadingStates.gallerys, true))

        fetchData(Endpoints.GALLERYS(project_id))
            .then(res => res.json())
            .then(res => {
                setGallerys(res.data || {})
            }).catch(e => {
            setError(Strings.CONNECTION_ERROR_MESSAGE)
        })
            .finally(() => setIsLoading(loadingState(loading, loadingStates.gallerys, false)))
    }

    const createGallery = (projectId, cb) => {
        setError('')
        setMessage('')

        if (!photo) {
            setError('All fields are required')
            return
        }

        setIsLoading(loadingState(loading, loadingStates.createGallery, true))

        const data = new FormData()
        data.append("photo", photo)
        data.append("project_id", projectId)

        postData(Endpoints.GALLERY, data)
            .then(res => res.json())
            .then(res => {
                if (!res.success) {
                    setError(res.errors?.[0] || res.message)
                    return;
                }

                setMessage(res.data)

                setTimeout(() => {
                    setMessage('')
                    setError('')
                }, 1500)

                cb?.()

            }).catch(e => {
            setError(Strings.CONNECTION_ERROR_MESSAGE)
        })
            .finally(() => setIsLoading(loadingState(loading, loadingStates.createGallery, false)))
    }


    const deleteMedia = (id, cb) => {
        setIsLoading(loadingState(loading, loadingStates.updateGallery, true))

        const data = new FormData()
        data.append("id", id)

        postData(Endpoints.DELETE_GALLERY, data)
            .then(res => res.json())
            .then(res => {
                if (!res.success) {
                    setError(res.errors?.[0] || res.message)
                    return;
                }

                setMessage(res.data)

                setTimeout(() => {
                    setMessage('')
                    setError('')
                }, 1500)

                cb?.()

            }).catch(e => {
            setError(Strings.CONNECTION_ERROR_MESSAGE)
        })
            .finally(() => setIsLoading(loadingState(loading, loadingStates.updateGallery, false)))
    }

    return {
        gallerys,
        loading,
        error,
        loadGallerys,
        createGallery,
        createAnother,
        setCreateAnother,
        message,
        setMessage,
        loadingStates,
        photo,
        setPhoto,
        deleteMedia
    }

}
