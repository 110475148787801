import React from "react"
import MetaTitle from "../../components/MetaTitle"
import {PayPalButtons} from "@paypal/react-paypal-js";
import logo from '../../assets/images/logo_new.png'
import {Card, CardBody, Col, Container, Row} from "reactstrap";
import Copyright from "../../components/Copyright";

const PayPalSuccess = () => {

    return (
        <React.Fragment>
            <MetaTitle title={"Success | Pay with PayPal"} append={false}/>

            <div className="account-pages my-5 pt-sm-5">
                <Container>
                    <Row className="justify-content-center">
                        <Col md={8} lg={6} xl={5}>
                            <Card className="overflow-hidden">
                                <div className="bg-light bg-soft text-center pt-5">
                                    <img src={logo} alt="" className="rounded-circle" height={50}/>

                                    <div className="text-dark p-4">
                                        <h5>Payment successful!</h5>
                                        <p>We have successfully captured your payment and you'll be notified once your
                                            portfolio is updated.</p>
                                    </div>
                                </div>
                                <CardBody className="pt-0">

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>

        </React.Fragment>
    )
}

export default PayPalSuccess
