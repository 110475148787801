import TamashaButton from "./TamashaButton";
import React, {useState} from "react";
import {Modal} from "reactstrap";

export const DeleteButton = ({onDelete}) => {
    const [showDeleteDialog, setShowDeleteDialog] = useState(false)

    return (
        <>
            <TamashaButton outline extraClassName={"btn-outline-danger mb-2 me-3"}
                           onClick={() => setShowDeleteDialog(true)}><i
                className="bx bx-trash font-size-16 align-middle me-2"></i>{" "}Delete</TamashaButton>

            <Modal
                isOpen={showDeleteDialog}
                toggle={() => {
                    setShowDeleteDialog(!showDeleteDialog)
                }}
            >
                <div className="modal-header">
                    <h5 className="modal-title mt-0" id="myModalLabel">
                        Are you sure your want to delete this item?
                    </h5>
                    <button
                        type="button"
                        onClick={() => setShowDeleteDialog(false)}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <p>
                        Please note that this action may result in unexpected behaviour if this item has
                        related data
                    </p>
                </div>
                <div className="modal-footer">
                    <button
                        type="button"
                        onClick={() => setShowDeleteDialog(false)}
                        className="btn btn-secondary waves-effect"
                        data-dismiss="modal"
                    >
                        Cancel
                    </button>
                    <button
                        type="button"
                        className="btn btn-primary waves-effect waves-light"
                        onClick={() => {
                            setShowDeleteDialog(false)
                            onDelete()
                        }}
                    >
                        Confirm
                    </button>
                </div>
            </Modal>
        </>
    )
}
