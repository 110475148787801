import React, {useState} from 'react'
import {FormAlert} from "../../components/FormAlert";
import TextField from "../../components/TextField";
import ProgressView from "../../components/ProgressView";
import TamashaButton from "../../components/TamashaButton";
import {postData} from "../../util/Server";
import Endpoints from "../../util/Endpoints";
import Strings from "../../util/Strings";
import {Col, Row} from "reactstrap";
import FormContainer from "../../components/FormContainer";
import {useAuth} from "../../hooks/auth";


export const ChangePassword = ({history}) => {

    const [oldPassword, setOldPassword] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState('')
    const [message, setMessage] = useState('')

    const [currentPassword, setCurrentPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')

    const {logOutUser} = useAuth()

    const submit = () => {
        setError('')

        if (!newPassword || !confirmPassword || !currentPassword) {
            setError('All fields are required')
            return
        }

        if (newPassword !== confirmPassword) {
            setError('Passwords do not match')
            return
        }

        setIsLoading(true)

        const data = new FormData()
        data.append("old_password", currentPassword)
        data.append("new_password", newPassword)

        postData(Endpoints.CHANGE_PASSWORD, data)
            .then(res => res.json())
            .then(res => {
                setIsLoading(false)

                if (!res.success) {
                    setError(res.errors?.[0] || res.message)
                    return;
                }

                setMessage('Password successfully updated')

                setTimeout(() => logOutUser(), 1500)

            }).catch(e => {
            setIsLoading(false)
            setError(Strings.CONNECTION_ERROR_MESSAGE)
        })


    }

    return (
        <div>
            <FormContainer>
                <FormAlert message={message} error={error}/>

                <Row className={"mt-4"}>
                    <Col md={12}>
                        <TextField
                            type={'password'}
                            className="form-control"
                            placeholder={"Current password"}
                            onChange={e => setCurrentPassword(e.target.value)}
                            value={currentPassword}
                            label={"Current password"}/>

                    </Col>

                    <Col md={12}>
                        <TextField
                            type={'password'}
                            className="form-control"
                            placeholder={"New password"}
                            onChange={e => setNewPassword(e.target.value)}
                            value={newPassword}
                            label={"New password"}/>
                    </Col>

                    <Col md={12}>

                        <TextField
                            type={'password'}
                            className="form-control"
                            placeholder={"Confirm password"}
                            onChange={e => setConfirmPassword(e.target.value)}
                            value={confirmPassword}
                            label={"Confirm new password"}/>

                    </Col>

                    <Col md={12}>
                        <div className={"float-end justify-content-center d-flex align-items-center"}>
                            {isLoading ?
                                <ProgressView/>
                                :
                                <TamashaButton
                                    type="submit"
                                    onClick={submit}
                                >
                                    Update
                                </TamashaButton>
                            }
                        </div>
                    </Col>
                </Row>
            </FormContainer>

        </div>
    )
}
