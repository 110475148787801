import React, {useEffect} from "react"
import {Card, CardBody, CardTitle, Col, Container, Row} from "reactstrap"

import Breadcrumbs from "../../components/Common/Breadcrumb"
import MetaTitle from "../../components/MetaTitle"
import CardUser from "./card-user";
import ProjectsTable from "../../components/Tables/ProjectsTable";
import AccountsTable from "../../components/Tables/AccountsTable";
import MiniWidget from "./mini-widget";
import {useDashboard} from "../../hooks/useDashboard";
import PortfolioTable from "../../components/Tables/PortfolioTable";

const Dashboard = () => {

    const {loadDashboard, loading, loadingStates, dashboard} = useDashboard()

    useEffect(() => {
        loadDashboard()
    }, [])

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTitle title={'Dashboard'}/>
                <Container fluid>
                    <Breadcrumbs title={'Dashboard'} breadcrumbItem={'Overview'} hideBackButton/>

                    <CardUser/>

                    <Row>
                        <MiniWidget reports={dashboard?.reports || []}/>
                    </Row>

                    <Row>
                        <Col lg="12">
                            <Card>
                                <CardBody>
                                    <CardTitle className="mb-4">Latest Projects</CardTitle>
                                    <ProjectsTable/>
                                </CardBody>
                            </Card>
                            <Card>
                                <CardBody>
                                    <CardTitle className="mb-4">Latest Account Registration</CardTitle>
                                    <AccountsTable/>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                </Container>
            </div>
        </React.Fragment>
    )
}

export default Dashboard
