import React from "react"

import {Card, CardBody, Col, Container, Row} from "reactstrap"

import Breadcrumbs from "../../components/Common/Breadcrumb"
import MetaTitle from "../../components/MetaTitle"
import TextField from "../../components/TextField"
import ProgressView from "../../components/ProgressView"
import FormContainer from "../../components/FormContainer";
import TamashaButton from "../../components/TamashaButton";
import {FormAlert} from "../../components/FormAlert";
import {usePropertys} from "../../hooks/usePropertys";

const NewProperty = props => {

    const {
        name,
        setName,
        setCreateAnother,
        createAnother,
        loading,
        error,
        message,
        loadingStates,
        createProperty
    } = usePropertys()


    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTitle title={"Create a property"}/>
                <Container fluid={true}>
                    <Breadcrumbs title={"Dashboard"} breadcrumbItem={"Create a property"} {...props}/>

                    <FormContainer>
                        <Card>
                            <CardBody>
                                <h4 className="card-title mb-4">Fill in the form below to add a new property</h4>

                                <FormAlert message={message} error={error}/>
                                <Row className={"mt-0"}>
                                    <Col md={12}>
                                        <TextField
                                            label={"Name"}
                                            onChange={e => setName(e.target.value)}
                                            value={name}
                                        />
                                    </Col>
                                </Row>

                                <hr/>

                                <div className={"float-end justify-content-center d-flex flex-row align-items-center"}>
                                    <div className="form-check form-check-end me-2">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            onChange={e => setCreateAnother(!createAnother)}
                                            checked={createAnother}
                                        />
                                        <label className="form-check-label text-muted">
                                            Create another
                                        </label>
                                    </div>

                                    {(loading[loadingStates.createProperty]) ?
                                        <ProgressView/> :
                                        <TamashaButton
                                            extraClassName={"float-end"}
                                            onClick={createProperty}>Create</TamashaButton>}
                                </div>

                            </CardBody>
                        </Card>
                    </FormContainer>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default NewProperty
