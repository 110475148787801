import React, {useEffect, useState} from "react"
import {Table, Tbody, Td, Th, Thead, Tr} from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import TamashaButton from "../TamashaButton";
import TextField from "../TextField";
import {Modal} from "reactstrap";

const QuestionsTable = ({rawQuestions = [], onSave, onUpdate}) => {

    const [openModal, setOpenModal] = useState(false)
    const [questions, setQuestions] = useState([])
    const [activeIndex, setActiveIndex] = useState(-1)

    const [title, setTitle] = useState('')
    const [options, setOptions] = useState('')

    useEffect(() => {
        setQuestions(rawQuestions)
    }, [rawQuestions])

    useEffect(() => {
        onSave?.(questions)
    }, [questions])

    const submit = () => {
        const currentArray = [...questions]
        if (activeIndex === -1) {
            currentArray.push({
                title,
                options
            })
        } else {
            currentArray[activeIndex] = {
                title,
                options
            }
        }
        setQuestions(currentArray)
        setOpenModal(false)
    }

    const deleteQuestion = (item, index) => {
        const newQuestions = questions.filter(i => item.title !== i.title)
        setQuestions(newQuestions)
    }

    return <>

        <div className="mt-3">

            <Modal
                isOpen={openModal}
                toggle={() => {
                    setOpenModal(!openModal)
                }}
            >
                <div className="modal-header">
                    <h5 className="modal-title mt-0" id="myModalLabel">
                        {activeIndex === -1 ? "Add" : "Edit"} question
                    </h5>
                    <button
                        type="button"
                        onClick={() => setOpenModal(false)}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <TextField
                        label={"Question"}
                        onChange={e => {
                            setTitle(e.target.value)
                        }}
                        value={title}
                    />
                    <TextField
                        type="textarea"
                        label={"Options - Separate options with the '|' (pipe) symbol. Add points after the options using '-' symbol. e.g Option A - 5 | Option B - 3"}
                        onChange={e => setOptions(e.target.value)}
                        value={options}
                        rows="3"
                    />
                </div>
                <div className="modal-footer">
                    <button
                        type="button"
                        onClick={() => setOpenModal(false)}
                        className="btn btn-secondary waves-effect"
                        data-dismiss="modal"
                    >
                        Cancel
                    </button>
                    <button
                        type="button"
                        className="btn btn-primary waves-effect waves-light"
                        onClick={() => {
                            submit()
                        }}
                    >
                        Confirm
                    </button>
                </div>
            </Modal>

            <div className="text-end mb-4">
                <TamashaButton
                    onClick={() => {
                        setActiveIndex(-1)
                        setTitle('')
                        setOptions('')
                        setOpenModal(true)
                    }}>
                    <i className="bx bx-plus font-size-16 align-middle me-2"/>{" "}Add a question
                </TamashaButton>
            </div>

            <div className="table-rep-plugin">
                <div
                    className="table-responsive mb-0"
                    data-pattern="priority-columns"
                >
                    <Table className="table table-striped table-bordered">
                        <Thead>
                            <Tr>
                                <Th data-priority="1">Question</Th>
                                <Th data-priority="1">Options</Th>
                                <Th data-priority="4"></Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {questions.map((question, b) =>
                                <Tr key={b.toString()}>
                                    <Td>
                                        {question.title}
                                    </Td>
                                    <Td>
                                        <ul>
                                            {question.options.split("|").map(o =>
                                                <li key={o}>{o}</li>
                                            )}
                                        </ul>
                                    </Td>
                                    <Td>
                                        <TamashaButton
                                            onClick={() => {
                                                setActiveIndex(b)
                                                setTitle(question.title)
                                                setOptions(question.options)
                                                setOpenModal(true)
                                            }}
                                            outline={true}
                                            color="primary"
                                            extraClassName={'btn-sm btn-rounded mb-2'}>
                                            Edit
                                        </TamashaButton>
                                        <br/>
                                        <TamashaButton
                                            onClick={() => deleteQuestion(question, b)}
                                            extraClassName={'btn-sm btn-rounded btn-danger'}>
                                            Delete
                                        </TamashaButton>
                                    </Td>
                                </Tr>
                            )}
                        </Tbody>
                    </Table>

                    <TamashaButton
                        extraClassName={'btn-success'}
                        onClick={() => {
                            onUpdate?.(questions)
                        }}>
                        Save questions
                    </TamashaButton>

                </div>
            </div>
        </div>

    </>

}

export default QuestionsTable
