import React from "react";
import {Badge} from "reactstrap";
import {getPillColor} from "../util/Util";

export const StatusPill = ({status}) => {
    return (
        <Badge
            className={"font-size-11 badge-soft-" + getPillColor(status)}
            color={getPillColor(status)}
            pill
        >
            {status.toUpperCase()}
        </Badge>
    )
}
