import React from "react"
import TamashaButtonLink from "../../components/TamashaButtonLink";
import SkillsTable from "../../components/Tables/SkillsTable";

const Skills = ({questId}) => {
    return (
        <React.Fragment>
            <div className="text-end mb-4">
                <TamashaButtonLink to={"/dashboard/new-skill/" + questId}><i
                    className="bx bx-plus font-size-16 align-middle me-2"></i>{" "}Create a skill
                </TamashaButtonLink>
            </div>

            <SkillsTable questId={questId}/>

        </React.Fragment>
    )
}

export default Skills
