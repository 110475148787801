import React from "react";
import {getFileUrl} from "../../../util/Server";
import {Link} from "react-router-dom";
import {getFormarttedNumber} from "../../../util/Util";
import DetailsComponent from "../../../components/DetailsComponent";

export const DetailsTab = ({project}) => {

    return (
        <DetailsComponent
            details={[
                {
                    title: "",
                    des: <img src={getFileUrl(project?.cover_photo)} height={300}/>
                },
                {
                    title: "Project Name",
                    des: project?.name
                },
                {
                    title: "Verification Status",
                    des: project?.verified ? "VERIFIED" : "PENDING VERIFICATION"
                },
                {
                    title: "Project Region",
                    des: project?.region
                },
                {
                    title: "Project Type",
                    des: project?.project_type || "-"
                },
                {
                    title: "Collection",
                    des: <Link
                        to={{
                            pathname: "/dashboard/category-groups/" + project.group_id,
                            state: {category: project.group}
                        }}>
                        {project.group?.name}
                    </Link>
                },
                {
                    title: "Category",
                    des: <Link
                        to={{
                            pathname: "/dashboard/categories/" + project.category_id,
                            state: {category: project.category}
                        }}>
                        {project.category.name}
                    </Link>
                },
                {
                    title: "Sub Category",
                    des: <Link
                        to={{
                            pathname: "/dashboard/sub-categories/" + project.sub_category_id,
                            state: {category: project.category}
                        }}>
                        {project.sub_category?.name}
                    </Link>
                },
                {
                    title: "Architects",
                    des: <Link
                        to={{
                            pathname: "/dashboard/architects/" + project.project_owner_id,
                            state: {architect: project.project_owner}
                        }}>
                        {project.project_owner.name}
                    </Link>
                },
                {
                    title: "Project Manufacturers",
                    des: <Link
                        to={{
                            pathname: "/dashboard/manufacturers/" + project.manufacturer_id,
                            state: {manufacturer: project.manufacturer}
                        }}>
                        {project.manufacturer?.name}
                    </Link>
                },
                {
                    title: "Materials",
                    des: <Link
                        to={{
                            pathname: "/dashboard/materials/" + project.materials,
                            state: {material: project.materials_details}
                        }}>
                        {project.materials_details?.name}
                    </Link>
                },
                {
                    title: "Description",
                    des: project?.description
                },
                {
                    title: "Start Date",
                    des: project?.start_date_string
                },
                {
                    title: "End Date",
                    des: project?.end_date_string
                },
                {
                    title: "Year",
                    des: project?.year
                },
                {
                    title: "Area in SQM",
                    des: project?.area
                },
                {
                    title: "Color",
                    des: project?.color
                },
                {
                    title: "Tags",
                    des: project?.tags
                },
                {
                    title: "Created At",
                    des: project?.created_at_string
                },
            ]}
        />
    )
}
