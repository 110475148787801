import React, {useState} from "react"
import {Alert, Card, CardBody, Col, Container, Row} from "reactstrap"

import {Link, useHistory} from "react-router-dom"
import MetaTitle from "../../components/MetaTitle"
import TextField from "../../components/TextField"
import ProgressView from "../../components/ProgressView"
import Copyright from "../../components/Copyright"
import TamashaButton from "../../components/TamashaButton";
import {postData} from "../../util/Server";
import Endpoints from "../../util/Endpoints";
import Strings from "../../util/Strings";

const ForgotPassword = ({history}) => {

    const [email, setEmail] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState('')
    const [message, setMessage] = useState('')

    const submit = () => {
        setError('')

        if (!email) {
            setError('Email is required')
            return
        }

        setIsLoading(true)

        postData(Endpoints.REQUEST_RESET_CODE, JSON.stringify({email}))
            .then(res => res.json())
            .then(res => {
                setIsLoading(false)
                if (res.success) {
                    history.push("/reset-password", {email})
                    return;
                }

                setError(res.message)

            }).catch(e => {
            setIsLoading(false)
            setError(Strings.CONNECTION_ERROR_MESSAGE)
        })


    }

    return (
        <React.Fragment>
            <MetaTitle title={"Forgot password"}/>
            <div className="home-btn d-none d-sm-block">
                <Link to="/" className="text-dark">
                    <i className="fas fa-home h2"/>
                </Link>
            </div>
            <div className="account-pages my-5 pt-sm-5">
                <Container>
                    <Row className="justify-content-center">
                        <Col md={8} lg={6} xl={5}>
                            <Card className="overflow-hidden">
                                <div className="bg-primary bg-soft">
                                    <Row>
                                        <Col xs={12}>
                                            <div className="p-4 text-primary">
                                                <h5 className="text-primary">Forgot your password?</h5>
                                                <span>Enter your email address below. We'll send a reset code to your email for use in the next step.</span>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                                <CardBody className="pt-2">
                                    <div className="p-2">

                                        {(error || message) ? (
                                            <Alert
                                                color={error ? "danger" : "success"}>{error ? error : message}</Alert>
                                        ) : null}

                                        <TextField type="email"
                                                   className="form-control"
                                                   placeholder={"Your email address"}
                                                   onChange={e => setEmail(e.target.value)}
                                                   value={email}
                                                   label={"Enter your email address"}/>


                                        <div className="mt-3 d-grid">
                                            {isLoading ?
                                                <div className={"text-center"}>
                                                    <ProgressView/>
                                                </div>
                                                :
                                                <TamashaButton
                                                    type="submit"
                                                    onClick={submit}
                                                >
                                                    Continue
                                                </TamashaButton>
                                            }
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                            <div className="mt-5 text-center">
                                <p>
                                    <Link
                                        to="/login"
                                        className="fw-medium text-dark"
                                    >
                                        {" "}
                                        Log in
                                    </Link>{" "}
                                </p>
                                <Copyright/>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}


export default ForgotPassword
