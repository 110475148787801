import React from "react"
import TamashaButtonLink from "../../components/TamashaButtonLink";
import SubCategoriesTable from "../../components/Tables/SubCategoriesTable";

const SubCategories = ({category}) => {
    return (
        <React.Fragment>
            <div className="text-end mb-4">
                <TamashaButtonLink to={{pathname: "/dashboard/new-sub-category", state: {category}}} outline>
                    Create a sub category
                </TamashaButtonLink>
            </div>

            <SubCategoriesTable categoryID={category?.id}/>
        </React.Fragment>
    )
}

export default SubCategories
