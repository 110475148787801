import RichTextEditor from 'react-rte';
import React, {useEffect, useState} from "react";
import {isEmpty} from "lodash";

export const TextEditor = ({value, setValue, label, defaultValue}) => {
    const [text, setText] = useState(RichTextEditor.createEmptyValue())

    useEffect(() => {
        setValue(text)
    }, [text])

    useEffect(() => {
        if (!isEmpty(defaultValue)) {
            setText(RichTextEditor.createValueFromString(defaultValue, 'html'))
        }
    }, [defaultValue])

    return (
        <div className="mb-3">
            <label htmlFor="floatingSelectGrid">{label}</label>
            <RichTextEditor value={text} onChange={setText} editorStyle={{minHeight: 200}}/>
        </div>
    )
}
