import React from "react"
import {Card, CardBody, Col, Media, Row,} from "reactstrap"

import {useAuth} from "../../hooks/auth";
import {getSalutation} from "../../util/Common";

const CardUser = ({stat}) => {

    const {activeUser} = useAuth()

    return (
        <React.Fragment>
            <Row>
                <Col lg="12">
                    <Card>
                        <CardBody>
                            <Row>
                                <Col lg="6">
                                    <Media>
                                        <div className="me-3">
                                            <img
                                                src={activeUser?.photo || "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"}
                                                alt=""
                                                className="avatar-md rounded-circle img-thumbnail"
                                            />
                                        </div>
                                        <Media className="align-self-center" body>
                                            <div className="text-muted">
                                                <p className="mb-2">{`${getSalutation()}, ${activeUser?.name}`}</p>
                                            </div>
                                        </Media>
                                    </Media>
                                </Col>

                                <Col lg="6" className="align-self-center">
                                    <div className="text-lg-center mt-4 mt-lg-0">
                                        <Row>
                                            <Col xs="4">
                                                <div>
                                                    <p className="text-muted text-truncate mb-2">
                                                        Submissions Today
                                                    </p>
                                                    <h5 className="mb-0">{stat?.today_submissions || 0}</h5>
                                                </div>
                                            </Col>
                                            <Col xs="4">
                                                <div>
                                                    <p className="text-muted text-truncate mb-2">
                                                        Accounts Today
                                                    </p>
                                                    <h5 className="mb-0">{stat?.today_accounts || 0}</h5>
                                                </div>
                                            </Col>
                                            <Col xs="4">
                                                <div>
                                                    <p className="text-muted text-truncate mb-2">
                                                        Projects Today
                                                    </p>
                                                    <h5 className="mb-0">{stat?.today_projects || 0}</h5>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    )
}

export default CardUser
