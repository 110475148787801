import React from "react";
import {getFileUrl} from "../../../util/Server";
import {Link} from "react-router-dom";
import {getFormarttedNumber} from "../../../util/Util";
import DetailsComponent from "../../../components/DetailsComponent";

export const DetailsTab = ({quest}) => {

    return (
        <DetailsComponent
            details={[
                {
                    title: "",
                    des: <img src={getFileUrl(quest?.cover_photo)} height={300}/>
                },
                {
                    title: "Title",
                    des: quest?.title
                },
                {
                    title: "Description",
                    des: quest?.description
                },
                {
                    title: "Deadline",
                    des: quest.has_deadline ? quest?.deadline_string : "N/A"
                },
            ]}
        />
    )
}
