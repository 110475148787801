import React, {useEffect} from "react"

import {BrowserRouter as Router, Switch} from "react-router-dom"

import {authRoutes, userRoutes} from "./routes/allRoutes"
import Authmiddleware from "./routes/middleware/Authmiddleware"
import VerticalLayout from "./components/VerticalLayout/"
import NonAuthLayout from "./components/NonAuthLayout"
import "./assets/scss/theme.scss"
import {ToastHolder} from "./util/Notify"
import {withTranslation} from "react-i18next";
import {useAuth} from "./hooks/auth";
import {isLocalEnvironment} from "./util/Endpoints";

if (!isLocalEnvironment()) console.log = () => {
};


const App = props => {

    const Layout = VerticalLayout

    const {isLoggedIn, isDoneChecking} = useAuth()

    return (
        <React.Fragment>
            <Switch>
                {authRoutes.map((route, idx) => (
                    <Authmiddleware
                        path={route.path}
                        layout={NonAuthLayout}
                        component={route.component}
                        key={idx}
                        isAuthProtected={false}
                        isLoggedIn={isLoggedIn}
                        isDoneChecking={isDoneChecking}
                    />
                ))}

                {userRoutes.map((route, idx) => (
                    <Authmiddleware
                        path={route.path}
                        layout={Layout}
                        component={route.component}
                        key={idx}
                        isAuthProtected={true}
                        exact
                        isLoggedIn={isLoggedIn}
                        isDoneChecking={isDoneChecking}
                    />
                ))}
            </Switch>
            <ToastHolder/>
        </React.Fragment>
    )
}

export default withTranslation()(App)
