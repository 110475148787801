import React from "react"
import {Col, Row} from "reactstrap";

const FormContainer = ({children}) =>
    <Row className="justify-content-center">
        <Col xl="8" sm="8">
            {children}
        </Col>
    </Row>


export default FormContainer
