import {useState} from "react";
import {fetchData} from "../util/Server";
import Endpoints from "../util/Endpoints";
import Strings from "../util/Strings";
import {loadingState} from "../util/Util";
import {ERROR, Notify} from "../util/Notify";

const loadingStates = {
    transactions: 'transactions',
}

export const useTransactions = () => {
    const [transactions, setTransactions] = useState({})
    const [loading, setIsLoading] = useState({})
    const [error, setError] = useState('')
    const [message, setMessage] = useState('')

    const loadTransactions = (project_id, page, query, records =  Strings.RECORDS_PER_PAGE, startDate, startTime, endDate, endTime, status) => {
        setIsLoading(loadingState(loading, loadingStates.transactions, true))

        fetchData(Endpoints.LIST_TRANSACTIONS(page, query, records, project_id, startDate, startTime, endDate, endTime, status))
            .then(res => res.json())
            .then(res => {
                setTransactions(res?.data || {})
            }).catch(e => {
            setError(Strings.CONNECTION_ERROR_MESSAGE)
        })
            .finally(() => setIsLoading(loadingState(loading, loadingStates.transactions, false)))
    }

    return {
        transactions,
        loading,
        error,
        loadTransactions,
        setTransactions,
        message,
        setMessage,
        loadingStates,
    }

}
