import React, {useEffect, useState} from "react"

import {Alert, Card, CardBody, Col, Container, Row} from "reactstrap"
import {Link} from "react-router-dom"

import profile from "assets/images/profile-img.png"
import Copyright from "../../components/Copyright"
import ProgressView from "../../components/ProgressView"
import MetaTitle from "../../components/MetaTitle"
import TextField from "../../components/TextField"
import {postData} from "../../util/Server";
import {useAuth} from "../../hooks/auth";
import Endpoints from "../../util/Endpoints";
import TamashaButton from "../../components/TamashaButton";
import Strings from "../../util/Strings";
import {FormAlert} from "../../components/FormAlert";

const Login = () => {

    const [isLoading, setIsLoading] = useState(false)
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [error, setError] = useState('')

    const [shouldChangePassword, setShouldChangePassword] = useState(false)

    const {loginUser} = useAuth()

    const handleLogin = () => {
        setError('')

        if (!email || !password) {
            setError('All fields are required')
            return
        }

        setIsLoading(true)

        const data = new FormData()
        data.append("email", email)
        data.append("password", password)

        postData(Endpoints.LOGIN, data)
            .then(res => res.json())
            .then(res => {
                setIsLoading(false)
                const result = res.data

                if (!result) {
                    setError(res.message)
                    return
                }

                loginUser(JSON.stringify(result.admin), result.token, () => {
                    window.location.assign("/dashboard")
                })
            }).catch(e => {
            setIsLoading(false)
            setError(Strings.CONNECTION_ERROR_MESSAGE)
        })
    }

    return (
        <React.Fragment>
            <MetaTitle title={"Login"}/>
            <div className="home-btn d-none d-sm-block">
                <Link to="/" className="text-dark">
                    <i className="fas fa-home h2"/>
                </Link>
            </div>
            <div className="account-pages my-5 pt-sm-5">
                <Container>
                    <Row className="justify-content-center">
                        <Col md={8} lg={6} xl={5}>
                            <Card className="overflow-hidden">
                                <div className="bg-primary bg-soft">
                                    <Row>
                                        <Col xs={7}>
                                            <div className="text-primary p-4">
                                                <h5 className="text-primary">Welcome Admin</h5>
                                                <p>Log in to continue</p>
                                            </div>
                                        </Col>
                                        <Col className="col-5 align-self-end">
                                            <img src={profile} alt="" className="img-fluid"/>
                                        </Col>
                                    </Row>
                                </div>
                                <CardBody className="pt-0">
                                    <div>
                                        <Link to="/" className="auth-logo-light">
                                            <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          {/*<img*/}
                            {/*    src={logo}*/}
                            {/*    alt=""*/}
                            {/*    className="rounded-circle"*/}
                            {/*    height="34"*/}
                            {/*/>*/}
                        </span>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="p-2">

                                        <FormAlert error={error} message={""}/>

                                        <TextField type="email"
                                                   className="form-control"
                                                   placeholder={"Your email address"}
                                                   onChange={e => setEmail(e.target.value)}
                                                   value={email}
                                                   label={"Email address"}/>


                                        <TextField type="password"
                                                   className="form-control"
                                                   placeholder={"Your password"}
                                                   onChange={e => setPassword(e.target.value)}
                                                   value={password}
                                                   label={"Password"}/>


                                        <div className="mt-3 d-grid">
                                            {(isLoading) ?
                                                <div className={"text-center"}>
                                                    <ProgressView/>
                                                </div>
                                                :
                                                <TamashaButton
                                                    type="submit"
                                                    onClick={handleLogin}
                                                >
                                                    Login
                                                </TamashaButton>
                                            }
                                            {/*<div className={"text-center mt-3"}>*/}
                                            {/*    <Link*/}
                                            {/*        to="/forgot-password"*/}
                                            {/*        className="text-dark text-decoration-underline"*/}
                                            {/*    >*/}
                                            {/*        Forgot your password?*/}
                                            {/*    </Link>*/}
                                            {/*</div>*/}
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                            <div className="mt-5 text-center">
                                <Copyright/>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default Login
