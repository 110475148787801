import React, {useEffect, useState} from "react"
import {Alert, Card, CardBody, Col, Container, Row} from "reactstrap"

import {Link, useHistory} from "react-router-dom"
import MetaTitle from "../../components/MetaTitle"
import TextField from "../../components/TextField"
import ProgressView from "../../components/ProgressView"
import Copyright from "../../components/Copyright"
import TamashaButton from "../../components/TamashaButton";
import {postData} from "../../util/Server";
import Endpoints from "../../util/Endpoints";
import Strings from "../../util/Strings";
import {FormAlert} from "../../components/FormAlert";

const ChangePassword = ({history, location}) => {

    const [email, setEmail] = useState('')
    const [code, setResetCode] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState('')
    const [message, setMessage] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')

    useEffect(() => {
        if (!location?.state?.email || !location?.state?.email) {
            history.push("/forgot-password")
            return
        }

        setEmail(location.state.email)
        setResetCode(location.state.code)
    }, [location])

    const submit = () => {
        setError('')

        if (!newPassword || !confirmPassword) {
            setError('All fields are required')
            return
        }

        if (newPassword !== confirmPassword) {
            setError('Passwords do not match')
            return
        }

        setIsLoading(true)

        postData(Endpoints.UPDATE_PASSWORD_RESET, JSON.stringify({
            email,
            reset_code: code,
            new_password: newPassword,
            confirm_new_password: confirmPassword
        }))
            .then(res => res.json())
            .then(res => {
                setIsLoading(false)

                if (!res.success) {
                    setError(res.errors?.[0] || res.message)
                    return;
                }

                setMessage('Password successfully updated')
                setTimeout(() => history.push("/login"), 1500)

            }).catch(e => {
            setIsLoading(false)
            setError(Strings.CONNECTION_ERROR_MESSAGE)
        })


    }

    return (
        <React.Fragment>
            <MetaTitle title={"Reset password"}/>
            <div className="home-btn d-none d-sm-block">
                <Link to="/" className="text-dark">
                    <i className="fas fa-home h2"/>
                </Link>
            </div>
            <div className="account-pages my-5 pt-sm-5">
                <Container>
                    <Row className="justify-content-center">
                        <Col md={8} lg={6} xl={5}>
                            <Card className="overflow-hidden">
                                <div className="bg-primary bg-soft">
                                    <Row>
                                        <Col xs={12}>
                                            <div className="p-4 text-primary">
                                                <h5 className="text-primary">Reset your password</h5>
                                                <span>Enter your new password information below</span>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                                <CardBody className="pt-2">
                                    <div className="p-2">

                                        <FormAlert message={message} error={error}/>

                                        <TextField
                                            type={'password'}
                                            className="form-control"
                                            placeholder={"New password"}
                                            onChange={e => setNewPassword(e.target.value)}
                                            value={newPassword}
                                            label={"New password"}/>

                                        <TextField
                                            type={'password'}
                                            className="form-control"
                                            placeholder={"Confirm password"}
                                            onChange={e => setConfirmPassword(e.target.value)}
                                            value={confirmPassword}
                                            label={"Confirm new password"}/>


                                        <div className="mt-3 d-grid">
                                            {isLoading ?
                                                <div className={"text-center"}>
                                                    <ProgressView/>
                                                </div>
                                                :
                                                <TamashaButton
                                                    type="submit"
                                                    onClick={submit}
                                                >
                                                    Update
                                                </TamashaButton>
                                            }
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                            <div className="mt-5 text-center">
                                <p>
                                    <Link
                                        to="/login"
                                        className="fw-medium text-dark"
                                    >
                                        {" "}
                                        Log in
                                    </Link>{" "}
                                </p>
                                <Copyright/>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}


export default ChangePassword
