import React from "react"
import {Link} from "react-router-dom"
import {BreadcrumbItem, Button, Col, Row} from "reactstrap"
import { withRouter } from 'react-router-dom';

const Breadcrumb = props => {

    return (
        <Row>
            <Col xs="12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                    <div className={'d-flex align-items-center'}>
                        {!props.hideBackButton && <Button color="link" className="btn btn-link ps-0" onClick={props.history?.goBack}>
                            <i className="bx bx-arrow-back font-size-24 align-middle me-2 text-dark"></i></Button>}
                        <h4 className="mb-0 font-size-18">{props.breadcrumbItem}</h4>
                    </div>
                    <div className="page-title-right">
                        <ol className="breadcrumb m-0">
                            <BreadcrumbItem>
                                <Link to="#">{props.title}</Link>
                            </BreadcrumbItem>
                            <BreadcrumbItem active>
                                <Link to="#">{props.breadcrumbItem}</Link>
                            </BreadcrumbItem>
                        </ol>
                    </div>
                </div>
            </Col>
        </Row>
    )
}

export default withRouter(Breadcrumb)
