import React, {useEffect, useState} from "react"
import {Table, Tbody, Td, Th, Thead, Tr} from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import ProgressView from "../ProgressView"
import TamashaButtonLink from "../TamashaButtonLink";
import Pagination from "../Pagination";
import useDebounce from "../../hooks/useDebounce";
import SearchAndFilter from "../SearchAndFilter";
import {useQuests} from "../../hooks/useQuests";
import {StatusPill} from "../StatusPill";

const QuestsTable = ({ownerId = "", categoryId = ""}) => {

    const {loadQuests, quests, loading, loadingStates} = useQuests()
    const [page, setPage] = useState(1)
    const [query, setQuery] = useState('')
    const [records, setRecords] = useState(10)

    const debouncedQuery = useDebounce(query, 500)

    useEffect(() => {
         if (debouncedQuery && page > 1) {
            setPage(1)
            return
        }

        loadQuests(page, debouncedQuery, records, ownerId, categoryId)
    }, [page, debouncedQuery, records, ownerId, categoryId])

     useEffect(() => {
        setPage(quests?.page)
    }, [quests?.page])

    return <>

        <SearchAndFilter onQuery={query => setQuery(query)} onRecords={records => setRecords(records)}/>

        {loading[loadingStates.quests] &&
        <div className={"p-2 text-center"}>
            <ProgressView/>
        </div>
        }

        {!loading[loadingStates.quests] && quests?.rows?.length === 0 &&
        <div className={"p-2 text-center"}>
            <p className="mb-2 text-muted">No data available</p>
        </div>
        }

        {quests?.rows?.length > 0 &&
        <div className="mt-3">

            <div className="table-rep-plugin">
                <div
                    className="table-responsive mb-0"
                    data-pattern="priority-columns"
                >
                    <Table className="table table-striped table-bordered">
                        <Thead>
                            <Tr>
                                <Th data-priority="1">Name</Th>
                                <Th data-priority="1">Description</Th>
                                <Th data-priority="1">Manager</Th>
                                <Th data-priority="1">Status</Th>
                                <Th data-priority="4"></Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {quests?.rows?.map((quest, b) =>
                                <Tr key={b.toString()}>
                                    <Td>{quest.title}</Td>
                                    <Td>{quest.description}</Td>
                                    <Td>
                                        <StatusPill status={quest?.is_app_managed ? "app managed" : "admin managed"}/>
                                    </Td>
                                    <Td>
                                        <StatusPill status={quest?.verified ? "verified" : "unverified"}/>
                                    </Td>
                                    <Td>
                                        <TamashaButtonLink
                                            to={{pathname: "/dashboard/quests/" + quest.id, state: {quest}}}
                                            outline={true}
                                            color="primary"
                                            extraClassName={'btn-sm btn-rounded'}>
                                            Details
                                        </TamashaButtonLink>
                                    </Td>
                                </Tr>
                            )}
                        </Tbody>
                    </Table>

                   <Pagination
                        currentPage={page}
                        numberOfPages={quests.total_pages}
                        onPageSelected={(page) => {
                             loadQuests(page, debouncedQuery, records, ownerId, categoryId)
                        }}/>
                </div>
            </div>
        </div>
        }
    </>

}

export default QuestsTable
