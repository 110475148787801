import {useState} from "react";
import {fetchData} from "../util/Server";
import Endpoints from "../util/Endpoints";
import Strings from "../util/Strings";
import {loadingState} from "../util/Util";

const loadingStates = {
    dashboard: 'dashboard',
}

export const useDashboard = () => {
    const [dashboard, setDashboard] = useState([])
    const [loading, setIsLoading] = useState({})
    const [error, setError] = useState('')
    const [message, setMessage] = useState('')

    const loadDashboard = () => {
        setIsLoading(loadingState(loading, loadingStates.dashboard, true))

        fetchData(Endpoints.DASHBOARD)
            .then(res => res.json())
            .then(res => {
                setDashboard(res.data)
            }).catch(e => {
            setError(Strings.CONNECTION_ERROR_MESSAGE)
        })
            .finally(() => setIsLoading(loadingState(loading, loadingStates.dashboard, false)))
    }

    return {
        dashboard,
        loading,
        error,
        loadDashboard,
        message,
        setMessage,
        loadingStates,
    }

}
