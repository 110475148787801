import React from "react"
import { Label} from "reactstrap"

import "react-datepicker/dist/react-datepicker.css"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"


 const DatePicker = (props) => <div className="mb-3">
      <Label>{props.label}</Label>
    <Flatpickr
        {...props}
        className="form-control d-block"
        placeholder="dd/MM/yyyy"
        onChange={(selectedDates, dateStr, instance) => {
            props.onSelected(dateStr)
        }}
        options={{
            altInput: true,
            altFormat: "F j, Y",
            dateFormat: "d/m/Y",
        }}
    />

</div>

export default DatePicker
