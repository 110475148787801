import React from "react"
import {Redirect} from "react-router-dom"

import Login from "../pages/Authentication/Login"
import Dashboard from "../pages/Dashboard/index"
import ForgotPassword from "../pages/Authentication/ForgotPassword";
import ResetPassword from "../pages/Authentication/ResetPassword";
import ChangePassword from "../pages/Authentication/ChangePassword";
import Settings from "../pages/Settings/Settings";
import NewProject from "../pages/Projects/NewProject";
import ProjectDetail from "../pages/Projects/ProjectDetail";
import Projects from "../pages/Projects/Projects";
import Accounts from "../pages/Accounts/Accounts";
import AccountDetail from "../pages/Accounts/AccountDetail";
import NewCategory from "../pages/Categories/NewCategory";
import CategoryDetail from "../pages/Categories/CategoryDetail";
import Categories from "../pages/Categories/Categories";
import Quests from "../pages/Quests/Quests";
import NewQuest from "../pages/Quests/NewQuest";
import QuestDetail from "../pages/Quests/QuestDetail";
import NewSkill from "../pages/Skills/NewSkill";
import SkillDetail from "../pages/Skills/SkillDetail";
import NewGroup from "../pages/Groups/NewGroup";
import GroupDetail from "../pages/Groups/GroupDetail";
import Groups from "../pages/Groups/Groups";
import EditGroupDetail from "../pages/Groups/EditGroupDetail";
import EditCategory from "../pages/Categories/EditCategory";
import Transactions from "../pages/Transactions/Transactions";
import PayPal from "../pages/PayPal/PayPal";
import PayPalError from "../pages/PayPal/PayPalError";
import PayPalSuccess from "../pages/PayPal/PayPalSuccess";
import Architects from "../pages/Architects/Architects";
import ArchitectDetail from "../pages/Architects/ArchitectDetail";
import NewArchitect from "../pages/Architects/NewArchitect";
import Manufacturers from "../pages/Manufacturers/Manufacturers";
import ManufacturerDetail from "../pages/Manufacturers/ManufacturerDetail";
import NewManufacturer from "../pages/Manufacturers/NewManufacturer";
import NewSubCategory from "../pages/SubCategories/NewSubCategory";
import EditSubCategory from "../pages/SubCategories/EditSubCategory";
import SubCategoryDetail from "../pages/SubCategories/SubCategoryDetail";
import Materials from "../pages/Materials/Materials";
import MaterialDetail from "../pages/Materials/MaterialDetail";
import EditMaterialDetail from "../pages/Materials/EditMaterialDetail";
import NewMaterial from "../pages/Materials/NewMaterial";
import Properties from "../pages/Property/Properties";
import NewProperty from "../pages/Property/NewProperty";
import EditPropertyDetail from "../pages/Property/EditPropertyDetail";
import PropertyDetail from "../pages/Property/PropertyDetail";
import Products from "../pages/Products/Products";
import NewProduct from "../pages/Products/NewProduct";

const userRoutes = [

    {path: "/dashboard/properties", component: Properties},
    {path: "/dashboard/properties/:id", component: PropertyDetail},
    {path: "/dashboard/property/edit/:id", component: EditPropertyDetail},
    {path: "/dashboard/new-property", component: NewProperty},

    {path: "/dashboard/materials", component: Materials},
    {path: "/dashboard/materials/:id", component: MaterialDetail},
    {path: "/dashboard/material/edit/:id", component: EditMaterialDetail},
    {path: "/dashboard/new-material", component: NewMaterial},

    {path: "/dashboard/transactions", component: Transactions},

    {path: "/dashboard/skills/:id", component: SkillDetail},
    {path: "/dashboard/new-skill/:id", component: NewSkill},

    {path: "/dashboard/quests", component: Quests},
    {path: "/dashboard/quests/:id", component: QuestDetail},
    {path: "/dashboard/new-quest", component: NewQuest},

    {path: "/dashboard/categories", component: Categories},
    {path: "/dashboard/categories/:id", component: CategoryDetail},
    {path: "/dashboard/category/edit/:id", component: EditCategory},
    {path: "/dashboard/new-category", component: NewCategory},

    {path: "/dashboard/sub-categories/:id", component: SubCategoryDetail},
    {path: "/dashboard/sub-category/edit/:id", component: EditSubCategory},
    {path: "/dashboard/new-sub-category", component: NewSubCategory},

    {path: "/dashboard/category-groups", component: Groups},
    {path: "/dashboard/category-groups/:id", component: GroupDetail},
    {path: "/dashboard/category-group/edit/:id", component: EditGroupDetail},
    {path: "/dashboard/new-category-group", component: NewGroup},

    {path: "/dashboard/projects", component: Projects},
    {path: "/dashboard/projects/:id", component: ProjectDetail},
    {path: "/dashboard/new-project", component: NewProject},

    {path: "/dashboard/products", component: Products},
    {path: "/dashboard/new-product", component: NewProduct},

    {path: "/dashboard/architects", component: Architects},
    {path: "/dashboard/architects/:id", component: ArchitectDetail},
    {path: "/dashboard/new-architect", component: NewArchitect},

    {path: "/dashboard/manufacturers", component: Manufacturers},
    {path: "/dashboard/manufacturers/:id", component: ManufacturerDetail},
    {path: "/dashboard/new-manufacturer", component: NewManufacturer},

    {path: "/dashboard/settings", component: Settings},

    {path: "/dashboard/accounts", component: Accounts},
    {path: "/dashboard/accounts/:id", component: AccountDetail},

    {path: "/dashboard", component: Dashboard, title: "Overview"},
    {path: "/", exact: true, component: () => <Redirect to="/dashboard"/>},
]

const authRoutes = [
    {path: "/login", component: Login},
    {path: "/forgot-password", component: ForgotPassword},
    {path: "/reset-password", component: ResetPassword},
    {path: "/update-password", component: ChangePassword},
    {path: "/paypal", component: PayPal},
    {path: "/paypal-success", component: PayPalSuccess},
    {path: "/paypal-error", component: PayPalError},

]

export {userRoutes, authRoutes}
