import React from "react"

import {Card, CardBody, Container} from "reactstrap"

import Breadcrumbs from "../../components/Common/Breadcrumb"
import MetaTitle from "../../components/MetaTitle"
import TamashaButtonLink from "../../components/TamashaButtonLink";
import ArchitectsTable from "../../components/Tables/ArchitectsTable";

const Architects = props => {
    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTitle title="Architects"/>
                <Container fluid={true}>
                    <Breadcrumbs title="Dashboard" breadcrumbItem="Architects" {...props}/>
                    <Card>
                        <CardBody>

                            <div className="text-end mb-4">
                                <TamashaButtonLink to={"/dashboard/new-architect"}>
                                    <i className="bx bx-plus font-size-16 align-middle me-2"/>{" "}New architect
                                </TamashaButtonLink>
                            </div>

                            <ArchitectsTable/>

                        </CardBody>
                    </Card>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default Architects
