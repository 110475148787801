import React, {useEffect, useState} from "react"
import {Table, Tbody, Td, Th, Thead, Tr} from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import ProgressView from "../ProgressView"
import TamashaButtonLink from "../TamashaButtonLink";
import Pagination from "../Pagination";
import useDebounce from "../../hooks/useDebounce";
import SearchAndFilter from "../SearchAndFilter";
import {useProjects} from "../../hooks/useProjects";
import {Link} from "react-router-dom";
import Strings from "../../util/Strings";

const ProjectsTable = ({
                           ownerId = "",
                           categoryId = "",
                           groupId = "",
                           subCategoryId = "",
                           propertyId = "",
                           entityType = "project"
                       }) => {

    const {loadProjects, projects, loading, loadingStates} = useProjects()
    const [page, setPage] = useState(1)
    const [query, setQuery] = useState('')
    const [records, setRecords] = useState(Strings.RECORDS_PER_PAGE)

    const debouncedQuery = useDebounce(query, 500)

    useEffect(() => {
        if (debouncedQuery && page > 1) {
            setPage(1)
            return
        }

        loadProjects({
            offset:page,
            query:debouncedQuery,
            records,
            projectOwnerId: ownerId,
            categoryId,
            groupId,
            subCategoryId,
            propertyId,
            entityType
        })
    }, [page, debouncedQuery, records, ownerId, categoryId, groupId, subCategoryId, propertyId, entityType])

    useEffect(() => {
        setPage(projects?.page)
    }, [projects?.page])


    return <>

        <SearchAndFilter onQuery={query => setQuery(query)} onRecords={records => setRecords(records)}/>

        {loading[loadingStates.projects] &&
        <div className={"p-2 text-center"}>
            <ProgressView/>
        </div>
        }

        {!loading[loadingStates.projects] && projects?.rows?.length === 0 &&
        <div className={"p-2 text-center"}>
            <p className="mb-2 text-muted">No data available</p>
        </div>
        }

        {projects?.rows?.length > 0 &&
        <div className="mt-3">

            <div className="table-rep-plugin">
                <div
                    className="table-responsive mb-0"
                    data-pattern="priority-columns"
                >
                    <Table className="table table-striped table-bordered">
                        <Thead>
                            <Tr>
                                <Th data-priority="1">Name</Th>
                                <Th data-priority="1">Region</Th>
                                <Th data-priority="1">Collection</Th>
                                <Th data-priority="1">Category</Th>
                                <Th data-priority="1">Sub Category</Th>
                                <Th data-priority="1">Architect</Th>
                                <Th data-priority="4"></Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {projects?.rows.map((project, b) =>
                                <Tr key={b.toString()}>
                                    <Td>{project.name}</Td>
                                    <Td>{project.region}</Td>
                                    <Td>
                                        <Link
                                            to={{
                                                pathname: "/dashboard/category-groups/" + project.group_id,
                                                state: {category: project.group}
                                            }}>
                                            {project.group?.name}
                                        </Link>
                                    </Td>
                                    <Td>
                                        <Link
                                            to={{
                                                pathname: "/dashboard/categories/" + project.category_id,
                                                state: {category: project.category}
                                            }}>
                                            {project.category?.name}
                                        </Link>
                                    </Td>
                                    <Td>
                                        <Link
                                            to={{
                                                pathname: "/dashboard/sub-categories/" + project.sub_category_id,
                                                state: {category: project.category}
                                            }}>
                                            {project.sub_category?.name}
                                        </Link>
                                    </Td>
                                    <Td>
                                        <Link
                                            to={{
                                                pathname: "/dashboard/architects/" + project.project_owner_id,
                                                state: {architect: project.project_owner}
                                            }}>
                                            {project.project_owner?.name}
                                        </Link>
                                    </Td>
                                    <Td>
                                        <TamashaButtonLink
                                            to={{pathname: "/dashboard/projects/" + project.id, state: {project}}}
                                            outline={true}
                                            color="primary"
                                            extraClassName={'btn-sm btn-rounded'}>
                                            Details
                                        </TamashaButtonLink>
                                    </Td>
                                </Tr>
                            )}
                        </Tbody>
                    </Table>

                    <Pagination
                        currentPage={page}
                        numberOfPages={projects.total_pages}
                        onPageSelected={(page) => {
                            loadProjects(page, debouncedQuery, records, ownerId, categoryId, groupId)
                        }}/>


                </div>
            </div>
        </div>
        }
    </>

}

export default ProjectsTable
