import React, {useEffect, useState} from "react"
import MetaTitle from "../../components/MetaTitle"
import {PayPalButtons, PayPalScriptProvider} from "@paypal/react-paypal-js";
import logo from '../../assets/images/logo_new.png'
import {Card, CardBody, Col, Container, Row} from "reactstrap";
import Copyright from "../../components/Copyright";
import {getQueryVariable, postData} from "../../util/Server";
import Endpoints from "../../util/Endpoints";
import Strings from "../../util/Strings";
import {ERROR, Notify} from "../../util/Notify";
import ProgressView from "../../components/ProgressView";

const PayPal = () => {

    const [isLoading, setIsLoading] = useState(false)
    const [payParams, setPayParams] = useState(null)
    const introTitle = getQueryVariable('intro') || "Pledge"

    useEffect(() => {
        //https://d889-41-90-49-51.ngrok.io/paypal?amount=20&id=ce7khuu630oqq391r2r0&title=Salmon%20Cannon%20(EU)&u=ce7k8ou630oqq391r2kg&cur=EUR
        const amount = parseInt(getQueryVariable('amount')) || 0
        const projectTitle = getQueryVariable('title')
        const userId = getQueryVariable('u')
        const currency = 'EUR'
        const projectId = getQueryVariable('id')

        if (amount === 0) {
            //Notify("Invalid request", ERROR)
            return
        }

        setIsLoading(true)

        const data = new FormData()
        data.append("project_id", projectId)
        data.append("token", userId)
        data.append("amount", amount)

        postData(Endpoints.INITIATE_PAYPAL, data)
            .then(res => res.json())
            .then(res => {
                setIsLoading(false)
                const result = res.data

                if (!result) {
                    Notify(res.message, ERROR)
                    return
                }

                setPayParams({
                    amount,
                    projectTitle,
                    userId,
                    currency,
                    projectId,
                    ref: result.transaction_id,
                    clientID: result.pay_pal_client_id
                })

            }).catch(e => {
            setIsLoading(false)
            Notify(Strings.CONNECTION_ERROR_MESSAGE, ERROR)
        })


    }, [])


    const updatePayment = (orderId, tID) => {
        const userId = getQueryVariable('u')

        setIsLoading(true)

        const data = new FormData()
        data.append("x", orderId)
        data.append("y", tID)
        data.append("t", userId)

        postData(Endpoints.CONFIRM_PAYPAL, data)
            .then(res => res.json())
            .then(res => {
                setIsLoading(false)
                const result = res.data

                if (!result) {
                    Notify(res.message, ERROR)
                    return
                }

                window.location.assign("/paypal-success")

            }).catch(e => {
            setIsLoading(false)
            Notify(Strings.CONNECTION_ERROR_MESSAGE, ERROR)
        })

    }

    return (
        <React.Fragment>
            <MetaTitle title={"Pay with PayPal"} append={false}/>

            {isLoading &&
            <div className={"text-center p-5"}>
                <ProgressView/>
            </div>
            }

            {(payParams && !isLoading) &&
            <PayPalScriptProvider
                options={{
                    "client-id": payParams.clientID || "AfLZJhUtnjxUP25MpQy3P20xzAQCU4MjxbfY5MPc0z0a4T4R0b9t7cTXSP0GDlSl0VUtOK5aQ9eITvCW",
                    currency: payParams.currency
                }}>
                <div className="account-pages my-5 pt-sm-5">
                    <Container>
                        <Row className="justify-content-center">
                            <Col md={8} lg={6} xl={5}>
                                <Card className="overflow-hidden">
                                    <div className="bg-light bg-soft text-center pt-5">
                                        <img src={logo} alt="" className="rounded-circle" height={50}/>

                                        <div className="text-dark p-4">
                                            <h5>{introTitle === 'Invest' ? 'Pledge' : introTitle} to {payParams?.projectTitle}</h5>
                                            <p>Choose an option below to make your payment. </p>
                                        </div>
                                    </div>
                                    <CardBody className="pt-0">
                                        <div>
                                            <PayPalButtons
                                                createOrder={(data, actions) => {
                                                    return actions.order
                                                        .create({
                                                            purchase_units: [
                                                                {
                                                                    amount: {
                                                                        currency_code: payParams.currency,
                                                                        value: payParams.amount,
                                                                    },
                                                                    reference_id: payParams.ref,
                                                                    custom_id: payParams.ref
                                                                },
                                                            ],
                                                        })
                                                        .then((orderId) => {
                                                            // Your code here after create the order
                                                            return orderId;
                                                        });
                                                }}
                                                onApprove={function (data, actions) {

                                                    return actions.order.capture().then((order) => {
                                                        updatePayment(order.id, order.purchase_units?.[0].reference_id)
                                                    });
                                                }}
                                                onCancel={() => {
                                                    window.location.assign("/paypal-error")
                                                }}
                                                onError={() => {
                                                    window.location.assign("/paypal-error")
                                                }}
                                            />
                                        </div>
                                    </CardBody>
                                </Card>
                                <div className="mt-5 text-center">
                                    <Copyright/>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </PayPalScriptProvider>

            }

        </React.Fragment>
    )
}

export default PayPal
