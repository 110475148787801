import React, {useEffect, useState} from "react"
import {Table, Tbody, Td, Th, Thead, Tr} from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import ProgressView from "../ProgressView"
import TamashaButtonLink from "../TamashaButtonLink";
import CustomPagination from "../Pagination";
import useDebounce from "../../hooks/useDebounce";
import {useGroups} from "../../hooks/useGroups";

const GroupsTable = ({groupType = 'project'}) => {

    const {loadGroups, groups, loading, loadingStates} = useGroups()
    const [page, setPage] = useState(1)
    const [query, setQuery] = useState('')
    const [records, setRecords] = useState(10)

    const debouncedQuery = useDebounce(query, 500)

    useEffect(() => {
        loadGroups(0, debouncedQuery, records, groupType)
    }, [page, debouncedQuery, records, groupType])


    return <>

        {/*<SearchAndFilter onQuery={query => setQuery(query)} onRecords={records => setRecords(records)}/>*/}

        {loading[loadingStates.groups] &&
        <div className={"p-2 text-center"}>
            <ProgressView/>
        </div>
        }

        {!loading[loadingStates.groups] && groups.length === 0 &&
        <div className={"p-2 text-center"}>
            <p className="mb-2 text-muted">No data available</p>
        </div>
        }

        {groups.length > 0 &&
        <div className="mt-3">

            <div className="table-rep-plugin">
                <div
                    className="table-responsive mb-0"
                    data-pattern="priority-columns"
                >
                    <Table className="table table-striped table-bordered">
                        <Thead>
                            <Tr>
                                <Th data-priority="1">Name</Th>
                                <Th data-priority="1">Group Type</Th>
                                <Th data-priority="1">No Of Projects</Th>
                                <Th data-priority="4"></Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {groups.map((category, b) =>
                                <Tr key={b.toString()}>
                                    <Td>{category.name}</Td>
                                    <Td>{category.group_type?.toUpperCase()}</Td>
                                    <Td>{category.number_of_projects}</Td>
                                    <Td>
                                        <TamashaButtonLink
                                            to={{
                                                pathname: "/dashboard/category-groups/" + category.id,
                                                state: {category}
                                            }}
                                            outline={true}
                                            color="primary"
                                            extraClassName={'btn-sm btn-rounded'}>
                                            Details
                                        </TamashaButtonLink>
                                    </Td>
                                </Tr>
                            )}
                        </Tbody>
                    </Table>

                    {!debouncedQuery &&
                    <CustomPagination
                        onPrevious={() => setPage(groups.length - records)}
                        onNext={() => setPage(groups.length)}
                    />
                    }

                </div>
            </div>
        </div>
        }
    </>

}

export default GroupsTable
