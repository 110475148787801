import React, {useMemo} from "react";

const CustomPagination = ({numberOfPages = 1, currentPage, onPageSelected}) => {

    const pages = useMemo(() => {
        const labels = []
        for (let i = 0; i < numberOfPages; i++) {
            labels.push(i + 1)
        }
        return labels
    }, [numberOfPages])

    return (
        <div
            className="btn-toolbar mt-4 text-center"
            role="toolbar"
            aria-label="Toolbar with button groups"
        >
            <div
                className="btn-group me-2"
                role="group"
                aria-label="First group"
            >
                {pages?.map((page, index) => <button
                    disabled={page === currentPage}
                    key={page}
                    onClick={() => {
                        onPageSelected(index + 1)
                    }}
                    className={`btn ${page === currentPage ? 'btn btn-primary' : 'btn btn-outline-secondary'} `}>
                    {page}
                </button>)}
            </div>
        </div>
    )
}

export default CustomPagination
