import React, {useEffect, useState} from "react"
import {Table, Tbody, Td, Th, Thead, Tr} from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import ProgressView from "../ProgressView"
import TamashaButtonLink from "../TamashaButtonLink";
import CustomPagination from "../Pagination";
import useDebounce from "../../hooks/useDebounce";
import {usePropertys} from "../../hooks/usePropertys";

const PropertysTable = () => {

    const {loadPropertys, propertys, loading, loadingStates} = usePropertys()
    const [page, setPage] = useState(1)
    const [query, setQuery] = useState('')
    const [records, setRecords] = useState(10)

    const debouncedQuery = useDebounce(query, 500)

    useEffect(() => {
        loadPropertys(0, debouncedQuery, records)
    }, [page, debouncedQuery, records])


    return <>

        {/*<SearchAndFilter onQuery={query => setQuery(query)} onRecords={records => setRecords(records)}/>*/}

        {loading[loadingStates.propertys] &&
        <div className={"p-2 text-center"}>
            <ProgressView/>
        </div>
        }

        {!loading[loadingStates.propertys] && propertys.length === 0 &&
        <div className={"p-2 text-center"}>
            <p className="mb-2 text-muted">No data available</p>
        </div>
        }

        {propertys.length > 0 &&
        <div className="mt-3">

            <div className="table-rep-plugin">
                <div
                    className="table-responsive mb-0"
                    data-pattern="priority-columns"
                >
                    <Table className="table table-striped table-bordered">
                        <Thead>
                            <Tr>
                                <Th data-priority="1">Name</Th>
                                <Th data-priority="1">No Of Projects</Th>
                                <Th data-priority="4"></Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {propertys.map((property, b) =>
                                <Tr key={b.toString()}>
                                    <Td>{property.name}</Td>
                                    <Td>{property.number_of_projects}</Td>
                                    <Td>
                                        <TamashaButtonLink
                                            to={{pathname: "/dashboard/properties/" + property.id, state: {property}}}
                                            outline={true}
                                            color="primary"
                                            extraClassName={'btn-sm btn-rounded'}>
                                            Details
                                        </TamashaButtonLink>
                                    </Td>
                                </Tr>
                            )}
                        </Tbody>
                    </Table>

                    {!debouncedQuery &&
                    <CustomPagination
                        onPrevious={() => setPage(propertys.length - records)}
                        onNext={() => setPage(propertys.length)}
                    />
                    }

                </div>
            </div>
        </div>
        }
    </>

}

export default PropertysTable
