import React from "react"
import {Table} from "reactstrap";


const DetailsComponent = ({details = [], className=""}) =>
    <div className={"table-responsive " + className}>
        <Table className="table mb-0 table-bordered">
            <tbody>
            {details.map(a => a.des ? <tr>
                <th
                    scope="row"
                    style={{width: "400px"}}
                    className={"text-capitalize"}
                >
                    {a.title}
                </th>
                <td>{a.des}</td>
            </tr> : null)}
            </tbody>
        </Table>
    </div>

export default DetailsComponent
