import React from "react"

import {Card, CardBody, Col, Container, Row} from "reactstrap"

import Breadcrumbs from "../../components/Common/Breadcrumb"
import MetaTitle from "../../components/MetaTitle"
import TextField from "../../components/TextField"
import ProgressView from "../../components/ProgressView"
import FormContainer from "../../components/FormContainer";
import TamashaButton from "../../components/TamashaButton";
import {FormAlert} from "../../components/FormAlert";
import {useArchitects} from "../../hooks/useArchitects";
import FilePicker from "../../components/FilePicker";

const NewArchitect = props => {

    const {
        name,
        setName,
        setCreateAnother,
        createAnother,
        loading,
        error,
        message,
        loadingStates,
        photo,
        setPhoto,
        coverPhoto,
        setCoverPhoto,
        phoneNumber,
        setPhoneNumber,
        website,
        setWebsite,
        email,
        setEmail,
        about,
        setAbout,
        overview,
        setOverview,
        createArchitect
    } = useArchitects()


    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTitle title={"Create an architect"}/>
                <Container fluid={true}>
                    <Breadcrumbs title={"Dashboard"} breadcrumbItem={"Create an architect"} {...props}/>

                    <FormContainer>
                        <Card>
                            <CardBody>
                                <h4 className="card-title mb-4">Fill in the form below to add a new architect</h4>

                                <FormAlert message={message} error={error}/>
                                <Row className={"mt-0"}>
                                    <Col md={12}>
                                        <TextField
                                            label={"Name"}
                                            onChange={e => setName(e.target.value)}
                                            value={name}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <TextField
                                            label={"Contact email"}
                                            onChange={e => setEmail(e.target.value)}
                                            value={email}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <TextField
                                            label={"Contact phone number"}
                                            onChange={e => setPhoneNumber(e.target.value)}
                                            value={phoneNumber}
                                        />
                                    </Col>
                                    <Col md={12}>
                                        <TextField
                                            type="textarea"
                                            label={"Brief description"}
                                            onChange={e => setOverview(e.target.value)}
                                            value={overview}
                                            rows="3"
                                        />
                                    </Col>
                                    <Col md={12}>
                                        <TextField
                                            type="textarea"
                                            label={"Long description / Info"}
                                            onChange={e => setAbout(e.target.value)}
                                            value={about}
                                            rows="5"
                                        />
                                    </Col>
                                    <Col md={12}>
                                        <TextField
                                            label={"Website"}
                                            onChange={e => setWebsite(e.target.value)}
                                            value={website}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <FilePicker
                                            label={"Logo"}
                                            onChange={e => setPhoto(e.target.files[0])}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <FilePicker
                                            label={"Cover photo"}
                                            onChange={e => setCoverPhoto(e.target.files[0])}
                                        />
                                    </Col>
                                </Row>

                                <hr/>

                                <div className={"float-end justify-content-center d-flex flex-row align-items-center"}>
                                    <div className="form-check form-check-end me-2">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            onChange={e => setCreateAnother(!createAnother)}
                                            checked={createAnother}
                                        />
                                        <label className="form-check-label text-muted">
                                            Create another
                                        </label>
                                    </div>

                                    {(loading[loadingStates.createArchitect]) ?
                                        <ProgressView/> :
                                        <TamashaButton
                                            extraClassName={"float-end"}
                                            onClick={createArchitect}>Create</TamashaButton>}
                                </div>

                            </CardBody>
                        </Card>
                    </FormContainer>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default NewArchitect
