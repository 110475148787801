import React from "react"

import {Card, CardBody, Col, Container, Row} from "reactstrap"

import Breadcrumbs from "../../components/Common/Breadcrumb"
import MetaTitle from "../../components/MetaTitle"
import TextField from "../../components/TextField"
import ProgressView from "../../components/ProgressView"
import FormContainer from "../../components/FormContainer";
import TamashaButton from "../../components/TamashaButton";
import {FormAlert} from "../../components/FormAlert";
import SelectView from "../../components/SelectView";
import DatePicker from "../../components/DatePicker";
import {useQuests} from "../../hooks/useQuests";

const NewQuest = props => {

    const {
        name,
        setName,
        setCreateAnother,
        createAnother,
        loading,
        error,
        message,
        loadingStates,
        setPhoto,
        createQuest,
        deadline,
        setDeadline,
        description,
        setDescription,
        hasDeadline,
        setHasDeadline,
        isAppManaged,
        setIsAppManaged,
        rewardPoints,
        setRewardPoints,
        appID,
        setAppID,
        emails,
        setEmails
    } = useQuests()

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTitle title={"Create a quest"}/>
                <Container fluid={true}>
                    <Breadcrumbs title={"Dashboard"} breadcrumbItem={"Create a quest"} {...props}/>

                    <FormContainer>
                        <Card>
                            <CardBody>
                                <h4 className="card-title mb-4">Fill in the form below to add a new quest</h4>

                                <FormAlert message={message} error={error}/>
                                <Row className={"mt-0"}>
                                    <Col md={12}>
                                        <TextField
                                            label={"Name"}
                                            onChange={e => setName(e.target.value)}
                                            value={name}
                                        />
                                    </Col>
                                    <Col md={12}>
                                        <TextField
                                            type="textarea"
                                            label={"Description"}
                                            onChange={e => setDescription(e.target.value)}
                                            value={description}
                                            rows="3"
                                        />
                                    </Col>
                                    <Col md={12}>
                                        <label>Cover photo</label>
                                        <TextField
                                            type={'file'}
                                            label={""}
                                            onChange={e => setPhoto(e.target.files[0])}
                                        />
                                    </Col>
                                    <Col md={12}>
                                        <SelectView
                                            onChange={e => setIsAppManaged(e.target.value)}
                                            value={isAppManaged}
                                            label={"Is this quest managed within the mobile application or from admin? Quests managed from the application will need a custom UI and logic for them to be displayed, while those managed from admin, can easily be modified any time."}
                                        >
                                            <option value={0}>Managed from admin console (Can add skills)</option>
                                            <option value={1}>Managed from mobile application</option>
                                        </SelectView>
                                    </Col>
                                    {(parseInt(isAppManaged) === 1) &&
                                    <>
                                        <Col md={12}>
                                            <TextField
                                                label={"Unique app ID. This enables the app to load the correct component for this quest."}
                                                onChange={e => setAppID(e.target.value)}
                                                value={appID}
                                            />
                                        </Col>
                                    </>
                                    }

                                    <Col md={12}>
                                        <TextField
                                            type={"number"}
                                            label={"Total reward points"}
                                            onChange={e => setRewardPoints(e.target.value)}
                                            value={rewardPoints}
                                        />
                                    </Col>

                                    <Col md={12}>
                                        <TextField
                                            label={"Email visibility (Leave blank for everyone, and separate emails with commas for multiple emails.)"}
                                            onChange={e => setEmails(e.target.value)}
                                            value={emails}
                                        />
                                    </Col>

                                    <Col md={12}>
                                        <SelectView
                                            onChange={e => setHasDeadline(e.target.value)}
                                            value={hasDeadline}
                                            label={"Does this quest have a deadline?"}
                                        >
                                            <option value={0}>No, there's no deadline limitation</option>
                                            <option value={1}>Yes, there's a deadline limitation</option>
                                        </SelectView>
                                    </Col>

                                    {parseInt(hasDeadline) === 1 &&
                                    <Col md={12}>
                                        <DatePicker
                                            label={"Project Deadline"}
                                            onSelected={e => setDeadline(e)}
                                            value={deadline}
                                        />
                                    </Col>
                                    }

                                </Row>

                                <hr/>

                                <div className={"float-end justify-content-center d-flex flex-row align-items-center"}>
                                    <div className="form-check form-check-end me-2">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            onChange={e => setCreateAnother(!createAnother)}
                                            checked={createAnother}
                                        />
                                        <label className="form-check-label text-muted">
                                            Create another
                                        </label>
                                    </div>

                                    {(loading[loadingStates.createQuest]) ?
                                        <ProgressView/> :
                                        <TamashaButton
                                            extraClassName={"float-end"}
                                            onClick={createQuest}>Create</TamashaButton>}
                                </div>

                            </CardBody>
                        </Card>
                    </FormContainer>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default NewQuest
