import React, {useEffect, useState} from "react"

import {Card, CardBody, Container, Modal, Nav, NavItem, NavLink, TabContent, TabPane} from "reactstrap"

import Breadcrumbs from "../../components/Common/Breadcrumb"
import MetaTitle from "../../components/MetaTitle"
import classnames from "classnames";
import ProgressView from "../../components/ProgressView";
import TamashaButton from "../../components/TamashaButton";
import {FormAlert} from "../../components/FormAlert";
import {PageLoader} from "../../components/PageLoader";
import {useAccounts} from "../../hooks/useAccounts";
import PortfolioTable from "../../components/Tables/PortfolioTable";
import DetailsComponent from "../../components/DetailsComponent";
import {getFileUrl} from "../../util/Server";

const styles = {
    tabStyle: {
        cursor: "pointer"
    }
}

const AccountDetail = ({match, location, ...rest}) => {

    const [activeTab, setActiveTab] = useState(0)
    const toggle = tab => setActiveTab(tab)

    const [showDeleteDialog, setShowDeleteDialog] = useState(false)

    const {id} = match.params;

    const {account: fromParams} = location.state

    const {
        loading,
        error,
        message,
        loadingStates,
        updateAccountstatus,
        findAccount,
        account
    } = useAccounts()

    useEffect(() => {
        findAccount(id)
    }, [id])

    return (
        <React.Fragment>
            {account ?
                <div className="page-content">
                    <MetaTitle title={`${account.first_name} ${account.last_name}`}/>
                    <Container fluid={true}>
                        <Breadcrumbs title="Dashboard"
                                     breadcrumbItem={`${account.first_name} ${account.last_name}`} {...rest}/>

                        <Modal
                            isOpen={showDeleteDialog}
                            toggle={() => {
                                setShowDeleteDialog(!showDeleteDialog)
                            }}
                        >
                            <div className="modal-header">
                                <h5 className="modal-title mt-0" id="myModalLabel">
                                    Are you sure your want to delete this role?
                                </h5>
                                <button
                                    type="button"
                                    onClick={() => setShowDeleteDialog(false)}
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <p>
                                    Please note that you cannot delete a role if already assigned to a user. In such a
                                    case, please remove this role from affected users before deleting.
                                </p>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    onClick={() => setShowDeleteDialog(false)}
                                    className="btn btn-secondary waves-effect"
                                    data-dismiss="modal"
                                >
                                    Cancel
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-primary waves-effect waves-light"
                                    onClick={() => {
                                        setShowDeleteDialog(false)

                                    }}
                                >
                                    Confirm
                                </button>
                            </div>
                        </Modal>

                        <Card>
                            <CardBody>

                                <div className={"mb-2 mt-2 d-flex justify-content-end"}>
                                    {loading[loadingStates.updateAccount] ? <ProgressView/> :
                                        <TamashaButton
                                            extraClassName={`${account.status === 1 ? "btn-danger" : "btn-success"} mb-2 me-3`}
                                            onClick={() => {
                                                updateAccountstatus(account?.id, account.status === 1 ? 0 : 1)
                                            }}>
                                            {account.status === 1 ? "Unverify account" : "Verify account"}
                                        </TamashaButton>}
                                </div>

                                <FormAlert message={message} error={error}/>

                                <Nav tabs>
                                    <NavItem>
                                        <NavLink
                                            style={styles.tabStyle}
                                            className={classnames({
                                                active: activeTab === 0,
                                            })}
                                            onClick={() => toggle(0)}
                                        >
                                            Details
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            style={styles.tabStyle}
                                            className={classnames({
                                                active: activeTab === 1,
                                            })}
                                            onClick={() => toggle(1)}
                                        >
                                            Pledges
                                        </NavLink>
                                    </NavItem>
                                </Nav>

                                <TabContent activeTab={activeTab} className="p-3 text-muted">
                                    <TabPane tabId={0}>
                                        <DetailsComponent
                                            details={[
                                                {
                                                    title: "",
                                                    des: account?.photo ?
                                                        <img src={getFileUrl(account?.photo)} height={300}/> : null
                                                },
                                                {
                                                    title: "First Name",
                                                    des: account.first_name
                                                },
                                                {
                                                    title: "Last Name",
                                                    des: account.last_name
                                                },
                                                {
                                                    title: "Phone Number",
                                                    des: account.phone_number
                                                },
                                                {
                                                    title: "Email",
                                                    des: account.email
                                                },
                                                {
                                                    title: "Phone Confirmed",
                                                    des: account.phone_confirmed === 1 ? "YES" : "NO"
                                                },
                                                {
                                                    title: "Date of Birth",
                                                    des: account.dob
                                                },
                                                {
                                                    title: "Country",
                                                    des: account.country
                                                },
                                                {
                                                    title: "Street",
                                                    des: account.street
                                                },
                                                {
                                                    title: "City",
                                                    des: account.city
                                                },
                                                {
                                                    title: "Postal Code",
                                                    des: account.postal_code
                                                },
                                                {
                                                    title: "Tax ID",
                                                    des: account.tax_id
                                                },
                                                {
                                                    title: "Investor Profile",
                                                    des: account.investor_profile
                                                },
                                                {
                                                    title: "Document Type",
                                                    des: account.document_type
                                                },
                                                {
                                                    title: "Front Document Picture",
                                                    des: <img src={getFileUrl(account?.document_front)} height={300}/>
                                                },
                                                {
                                                    title: "Back Document Picture",
                                                    des: <img src={getFileUrl(account?.document_back)} height={300}/>
                                                },
                                                {
                                                    title: "Registration Step",
                                                    des: account.next_step
                                                },
                                                {
                                                    title: "Status",
                                                    des: account.status === 1 ? "ACTIVE" : "INACTIVE"
                                                },
                                                {
                                                    title: "Date Registered",
                                                    des: account.date_added
                                                }
                                            ]}
                                        />
                                    </TabPane>
                                    <TabPane tabId={1}>
                                        <PortfolioTable userId={account?.id}/>
                                    </TabPane>
                                </TabContent>

                            </CardBody>
                        </Card>

                    </Container>
                </div>
                : <PageLoader/>
            }

        </React.Fragment>
    )
}

export default AccountDetail
