import React from "react"
import MetaTags from "react-meta-tags"
import Strings from "../util/Strings"


const MetaTitle = ({title, append = true}) =>  <MetaTags>
                <title>{title} | {append ? Strings.APP_DESCRIPTION : "maaktaba"}</title>
            </MetaTags>

export default MetaTitle
