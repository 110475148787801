import Strings from "./Strings";
import {isLocalEnvironment, isStagingEnvironment} from "./Endpoints";

const prefix = isLocalEnvironment() ? "" : isStagingEnvironment() ? "Staging_" : "Key_"

export const clearItem = (key) => {
    try {
        localStorage.removeItem(prefix + Strings.STORAGE_KEY + key);
    } catch (error) {
        console.log(error.message);
    }
};

export const storeItem = (key, value) => {
    try {
        localStorage.setItem(prefix + Strings.STORAGE_KEY + key, value);
    } catch (error) {
        console.log(error.message);
    }
};

export const retrieveItem = key => {
    let item = null;
    try {
        item = localStorage.getItem(prefix + Strings.STORAGE_KEY + key) || null;
    } catch (error) {
        console.log(error.message);
    }
    return item;
};

export const guidGenerator = () => {
    /**
     * @return {string}
     */
    let S4 = function () {
        return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    };
    return (S4() + S4() + "-" + S4() + "-" + S4() + "-" + S4() + "-" + S4() + S4() + S4());
};

export const encode = (input) => {
    let keyStr = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";
    let output = "";
    let chr1, chr2, chr3, enc1, enc2, enc3, enc4;
    let i = 0;

    while (i < input.length) {
        chr1 = input[i++];
        chr2 = i < input.length ? input[i++] : Number.NaN; // Not sure if the index
        chr3 = i < input.length ? input[i++] : Number.NaN; // checks are needed here

        enc1 = chr1 >> 2;
        enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
        enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
        enc4 = chr3 & 63;

        if (isNaN(chr2)) {
            enc3 = enc4 = 64;
        } else if (isNaN(chr3)) {
            enc4 = 64;
        }
        output += keyStr.charAt(enc1) + keyStr.charAt(enc2) +
            keyStr.charAt(enc3) + keyStr.charAt(enc4);
    }
    return output;
};
