import React, {useEffect, useState} from "react"

import {Card, CardBody, Col, Container, Modal, Nav, NavItem, NavLink, Row, TabContent, TabPane} from "reactstrap"

import Breadcrumbs from "../../components/Common/Breadcrumb"
import MetaTitle from "../../components/MetaTitle"
import classnames from "classnames";
import ProgressView from "../../components/ProgressView";
import TamashaButton from "../../components/TamashaButton";
import TextField from "../../components/TextField";
import {FormAlert} from "../../components/FormAlert";
import {PageLoader} from "../../components/PageLoader";
import {useArchitects} from "../../hooks/useArchitects";
import PortfolioTable from "../../components/Tables/PortfolioTable";
import ProjectsTable from "../../components/Tables/ProjectsTable";
import FilePicker from "../../components/FilePicker";
import DetailsComponent from "../../components/DetailsComponent";

const styles = {
    tabStyle: {
        cursor: "pointer"
    }
}


const ArchitectDetail = ({match, location, ...rest}) => {

    const [activeTab, setActiveTab] = useState(0)
    const toggle = tab => setActiveTab(tab)

    const [showDeleteDialog, setShowDeleteDialog] = useState(false)

    const {architect} = location.state

    const {
        name,
        setName,
        loading,
        error,
        message,
        loadingStates,
        photo,
        setPhoto,
        coverPhoto,
        setCoverPhoto,
        phoneNumber,
        setPhoneNumber,
        website,
        setWebsite,
        email,
        setEmail,
        about,
        setAbout,
        overview,
        setOverview,
        updateArchitectDetails,
        updateArchitectCover,
        updateArchitectPhoto,
    } = useArchitects()


    useEffect(() => {
        if (!architect) {
            return
        }

        setName(architect?.name)
        setEmail(architect?.email)
        setPhoneNumber(architect?.phone_number)
        setWebsite(architect?.website)
        setAbout(architect?.about)
        setOverview(architect?.overview)

    }, [architect])

    return (
        <React.Fragment>
            {architect ?
                <div className="page-content">
                    <MetaTitle title={architect.name}/>
                    <Container fluid={true}>
                        <Breadcrumbs title="Dashboard" breadcrumbItem={architect.name} {...rest}/>

                        <Modal
                            isOpen={showDeleteDialog}
                            toggle={() => {
                                setShowDeleteDialog(!showDeleteDialog)
                            }}
                        >
                            <div className="modal-header">
                                <h5 className="modal-title mt-0" id="myModalLabel">
                                    Are you sure your want to delete this architect?
                                </h5>
                                <button
                                    type="button"
                                    onClick={() => setShowDeleteDialog(false)}
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <p>
                                    Please note that this action may result in unexpected behaviour if this architect has existing projects
                                </p>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    onClick={() => setShowDeleteDialog(false)}
                                    className="btn btn-secondary waves-effect"
                                    data-dismiss="modal"
                                >
                                    Cancel
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-primary waves-effect waves-light"
                                    onClick={() => {
                                        setShowDeleteDialog(false)
                                    }}
                                >
                                    Confirm
                                </button>
                            </div>
                        </Modal>

                        <Card>
                            <CardBody>

                                <div className={"mb-2 mt-2 d-flex justify-content-end"}>
                                    {loading[loadingStates.deleteArchitect] ? <ProgressView/> :
                                        <TamashaButton outline extraClassName={"btn-outline-danger mb-2 me-3"}
                                                       onClick={() => setShowDeleteDialog(true)}><i
                                            className="bx bx-trash font-size-16 align-middle me-2"></i>{" "}Delete
                                            architect</TamashaButton>
                                    }
                                </div>

                                <FormAlert message={message} error={error}/>

                                <Nav tabs>
                                    <NavItem>
                                        <NavLink
                                            style={styles.tabStyle}
                                            className={classnames({
                                                active: activeTab === 0,
                                            })}
                                            onClick={() => toggle(0)}
                                        >
                                            Details
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            style={styles.tabStyle}
                                            className={classnames({
                                                active: activeTab === 1,
                                            })}
                                            onClick={() => toggle(1)}
                                        >
                                            Projects
                                        </NavLink>
                                    </NavItem>

                                    <NavItem>
                                        <NavLink
                                            style={styles.tabStyle}
                                            className={classnames({
                                                active: activeTab === 3,
                                            })}
                                            onClick={() => toggle(3)}
                                        >
                                            Update Details
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            style={styles.tabStyle}
                                            className={classnames({
                                                active: activeTab === 4,
                                            })}
                                            onClick={() => toggle(4)}
                                        >
                                            Update Logo
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            style={styles.tabStyle}
                                            className={classnames({
                                                active: activeTab === 5,
                                            })}
                                            onClick={() => toggle(5)}
                                        >
                                            Update Cover Photo
                                        </NavLink>
                                    </NavItem>
                                </Nav>

                                <TabContent activeTab={activeTab} className="p-3 text-muted">
                                    <TabPane tabId={0}>
                                        <DetailsComponent
                                            details={[
                                                {title: "Name", des: architect.name},
                                                {title: "Email", des: architect.email},
                                                {title: "Phone Number", des: architect.phone_number},
                                                {title: "Overview", des: architect.overview},
                                                {title: "About", des: architect.about},
                                                {title: "Website", des: architect.website},
                                            ]}
                                        />
                                    </TabPane>
                                    <TabPane tabId={1}>
                                        <ProjectsTable ownerId={architect?.id}/>
                                    </TabPane>
                                    <TabPane tabId={3}>
                                        <Row className={"mt-0"}>
                                            <Col md={12}>
                                                <TextField
                                                    label={"Name"}
                                                    onChange={e => setName(e.target.value)}
                                                    value={name}
                                                />
                                            </Col>
                                            <Col md={6}>
                                                <TextField
                                                    label={"Contact email"}
                                                    onChange={e => setEmail(e.target.value)}
                                                    value={email}
                                                />
                                            </Col>
                                            <Col md={6}>
                                                <TextField
                                                    label={"Contact phone number"}
                                                    onChange={e => setPhoneNumber(e.target.value)}
                                                    value={phoneNumber}
                                                />
                                            </Col>
                                            <Col md={12}>
                                                <TextField
                                                    type="textarea"
                                                    label={"Brief description"}
                                                    onChange={e => setOverview(e.target.value)}
                                                    value={overview}
                                                    rows="3"
                                                />
                                            </Col>
                                            <Col md={12}>
                                                <TextField
                                                    type="textarea"
                                                    label={"Long description / Info"}
                                                    onChange={e => setAbout(e.target.value)}
                                                    value={about}
                                                    rows="5"
                                                />
                                            </Col>
                                            <Col md={12}>
                                                <TextField
                                                    label={"Website"}
                                                    onChange={e => setWebsite(e.target.value)}
                                                    value={website}
                                                />
                                            </Col>
                                        </Row>

                                        <div className={"float-end justify-content-end"}>
                                            {loading[loadingStates.updateArchitect] ? <ProgressView/> :
                                                <TamashaButton
                                                    extraClassName={"float-end"}
                                                    onClick={() => {
                                                        updateArchitectDetails(architect?.id)
                                                    }}>Update</TamashaButton>}
                                        </div>
                                    </TabPane>
                                    <TabPane tabId={4}>
                                        <Row className={"mt-0"}>
                                            <Col md={12}>
                                                <FilePicker
                                                    label={"Logo"}
                                                    onChange={e => setPhoto(e.target.files[0])}
                                                />
                                            </Col>
                                        </Row>

                                        <div className={"float-end justify-content-end"}>
                                            {loading[loadingStates.updateArchitect] ? <ProgressView/> :
                                                <TamashaButton
                                                    extraClassName={"float-end"}
                                                    onClick={() => {
                                                        updateArchitectPhoto(architect?.id)
                                                    }}>Update logo</TamashaButton>}
                                        </div>
                                    </TabPane>
                                    <TabPane tabId={5}>
                                        <Row className={"mt-0"}>
                                            <Col md={12}>
                                                <FilePicker
                                                    label={"Cover photo"}
                                                    onChange={e => setCoverPhoto(e.target.files[0])}
                                                />
                                            </Col>
                                        </Row>

                                        <div className={"float-end justify-content-end"}>
                                            {loading[loadingStates.updateArchitect] ? <ProgressView/> :
                                                <TamashaButton
                                                    extraClassName={"float-end"}
                                                    onClick={() => {
                                                        updateArchitectCover(architect?.id)
                                                    }}>Update logo</TamashaButton>}
                                        </div>
                                    </TabPane>
                                </TabContent>

                            </CardBody>
                        </Card>

                    </Container>
                </div>
                : <PageLoader/>
            }

        </React.Fragment>
    )
}

export default ArchitectDetail
