import {useState} from "react";
import {fetchData, postData} from "../util/Server";
import Endpoints from "../util/Endpoints";
import Strings from "../util/Strings";
import {loadingState} from "../util/Util";

const loadingStates = {
    files: 'files',
    createFile: 'createFile',
    updateFile: 'updateFile',
    deleteFile: 'deleteFile',
}

export const useFiles = () => {
    const [files, setFiles] = useState([])
    const [loading, setIsLoading] = useState({})
    const [error, setError] = useState('')
    const [message, setMessage] = useState('')

    const [photo, setPhoto] = useState('')
    const [name, setName] = useState('')

    const [createAnother, setCreateAnother] = useState(false)

    const loadFiles = ({offset, query, records = Strings.RECORDS_PER_PAGE, project_id}) => {
        setIsLoading(loadingState(loading, loadingStates.files, true))

        fetchData(Endpoints.FILES(project_id))
            .then(res => res.json())
            .then(res => {
                setFiles(res.data || {})
            }).catch(e => {
            setError(Strings.CONNECTION_ERROR_MESSAGE)
        })
            .finally(() => setIsLoading(loadingState(loading, loadingStates.files, false)))
    }

    const createFile = (projectId, cb) => {
        setError('')
        setMessage('')

        if (!photo) {
            setError('All fields are required')
            return
        }

        setIsLoading(loadingState(loading, loadingStates.createFile, true))

        const data = new FormData()
        data.append("file", photo)
        data.append("project_id", projectId)
        data.append("name", name)

        postData(Endpoints.FILE, data)
            .then(res => res.json())
            .then(res => {
                if (!res.success) {
                    setError(res.errors?.[0] || res.message)
                    return;
                }

                setMessage(res.data)

                setTimeout(() => {
                    setMessage('')
                    setError('')
                }, 1500)

                cb?.()

            }).catch(e => {
            setError(Strings.CONNECTION_ERROR_MESSAGE)
        })
            .finally(() => setIsLoading(loadingState(loading, loadingStates.createFile, false)))
    }


    const deleteMedia = (id, cb) => {
        setIsLoading(loadingState(loading, loadingStates.updateFile, true))

        const data = new FormData()
        data.append("id", id)

        postData(Endpoints.DELETE_FILE, data)
            .then(res => res.json())
            .then(res => {
                if (!res.success) {
                    setError(res.errors?.[0] || res.message)
                    return;
                }

                setMessage(res.data)

                setTimeout(() => {
                    setMessage('')
                    setError('')
                }, 1500)

                cb?.()

            }).catch(e => {
            setError(Strings.CONNECTION_ERROR_MESSAGE)
        })
            .finally(() => setIsLoading(loadingState(loading, loadingStates.updateFile, false)))
    }

    return {
        files,
        loading,
        error,
        loadFiles,
        createFile,
        createAnother,
        setCreateAnother,
        message,
        setMessage,
        loadingStates,
        photo,
        setPhoto,
        deleteMedia,
        name,
        setName,
    }

}
