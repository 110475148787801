import React from "react"
import {FormGroup, Input, InputGroup, Label} from "reactstrap"

import "react-datepicker/dist/react-datepicker.css"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"


const TimePicker = (props) => <div className="mb-3">
    <Label>{props.label}</Label>
    <Flatpickr
        {...props}
        className="form-control d-block"
        options={{
            enableTime: true,
            noCalendar: true,
            dateFormat: "H:i",
            time_24hr: true
        }}
        onChange={(selectedDates, dateStr, instance) => {
            props.onSelected(dateStr)
        }}
    />

</div>

export default TimePicker
