import React, {useEffect, useState} from "react"
import {Table, Tbody, Td, Th, Thead, Tr} from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import ProgressView from "../ProgressView"
import TamashaButtonLink from "../TamashaButtonLink";
import Pagination from "../Pagination";
import useDebounce from "../../hooks/useDebounce";
import SearchAndFilter from "../SearchAndFilter";
import {useArchitects} from "../../hooks/useArchitects";
import Strings from "../../util/Strings";

const ArchitectsTable = () => {

    const {loadArchitects, architects, loading, loadingStates} = useArchitects()
    const [page, setPage] = useState(1)
    const [query, setQuery] = useState('')
    const [records, setRecords] = useState(Strings.RECORDS_PER_PAGE)

    const debouncedQuery = useDebounce(query, 500)

    useEffect(() => {
        if (debouncedQuery && page > 1) {
            setPage(1)
            return
        }

        loadArchitects(page, debouncedQuery, records)
    }, [page, debouncedQuery, records])

    useEffect(() => {
        setPage(architects?.page)
    }, [architects?.page])

    return <>

        <SearchAndFilter onQuery={query => setQuery(query)} onRecords={records => setRecords(records)}/>

        {loading[loadingStates.architects] &&
        <div className={"p-2 text-center"}>
            <ProgressView/>
        </div>
        }

        {!loading[loadingStates.architects] && architects?.rows?.length === 0 &&
        <div className={"p-2 text-center"}>
            <p className="mb-2 text-muted">No data available</p>
        </div>
        }

        {architects?.rows?.length > 0 &&
        <div className="mt-3">

            <div className="table-rep-plugin">
                <div
                    className="table-responsive mb-0"
                    data-pattern="priority-columns"
                >
                    <Table className="table table-striped table-bordered">
                        <Thead>
                            <Tr>
                                <Th data-priority="1">Name</Th>
                                <Th data-priority="1">Email</Th>
                                <Th data-priority="1">Phone Number</Th>
                                <Th data-priority="4"></Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {architects?.rows?.map((architect, b) =>
                                <Tr key={b.toString()}>
                                    <Td>{architect.name}</Td>
                                    <Td>{architect.email}</Td>
                                    <Td>{architect.phone_number}</Td>
                                    <Td>
                                        <TamashaButtonLink
                                            to={{pathname: "/dashboard/architects/" + architect.id, state: {architect}}}
                                            outline={true}
                                            color="primary"
                                            extraClassName={'btn-sm btn-rounded'}>
                                            Details
                                        </TamashaButtonLink>
                                    </Td>
                                </Tr>
                            )}
                        </Tbody>
                    </Table>

                    <Pagination
                        currentPage={page}
                        numberOfPages={architects.total_pages}
                        onPageSelected={(page) => {
                            loadArchitects(page, debouncedQuery, records)
                        }}/>

                </div>
            </div>
        </div>
        }
    </>

}

export default ArchitectsTable
