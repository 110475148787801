import React, {createContext, useContext, useEffect, useState} from "react"
import {clearItem, retrieveItem, storeItem} from "../util/Storage";

const AuthContext = createContext({});

export const useAuth = () => useContext(AuthContext)

const AuthContextProvider = ({children, store}) => {

    const [isLoggedIn, setIsLoggedIn] = useState(false)
    const [isDoneChecking, setIsDoneChecking] = useState(false)
    const [activeUser, setActiveUser] = useState(null)

    useEffect(() => {
        const token = retrieveItem('token')
        const user = retrieveItem('user')

        token && user && setIsLoggedIn(true)
        user && setActiveUser(JSON.parse(user))

        setIsDoneChecking(true)
    }, [window.location.href])

    const loginUser = (user, token, callback) => {
        storeItem('user', user)
        storeItem('token', token)

        setIsLoggedIn(true)
        setIsDoneChecking(true)
        setActiveUser(JSON.parse(user))

        callback && callback()
    }


    const logOutUser = () => {
        clearItem('user')
        clearItem('token')
        clearItem('estate')

        window.location.assign("/login")
    }

    return <AuthContext.Provider value={{
        isLoggedIn,
        isDoneChecking,
        activeUser,
        loginUser,
        logOutUser,
    }}>
        {children}
    </AuthContext.Provider>
}

export default AuthContextProvider;
