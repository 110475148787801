import React from "react"

import {Card, CardBody, Container} from "reactstrap"

import Breadcrumbs from "../../components/Common/Breadcrumb"
import MetaTitle from "../../components/MetaTitle"
import AccountsTable from "../../components/Tables/AccountsTable";

const Accounts = props => {
    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTitle title="Accounts"/>
                <Container fluid={true}>
                    <Breadcrumbs title="Dashboard" breadcrumbItem="Accounts" {...props}/>
                    <Card>
                        <CardBody>
                            <AccountsTable/>
                        </CardBody>
                    </Card>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default Accounts
