import {useState} from "react";
import {fetchData, postData} from "../util/Server";
import Endpoints from "../util/Endpoints";
import Strings from "../util/Strings";
import {loadingState} from "../util/Util";

const loadingStates = {
    groups: 'groups',
    createGroup: 'createGroup',
    updateGroup: 'updateGroup',
    deleteGroup: 'deleteGroup',
}

export const useGroups = () => {
    const [groups, setGroups] = useState([])
    const [loading, setIsLoading] = useState({})
    const [error, setError] = useState('')
    const [message, setMessage] = useState('')
    const [groupType, setGroupType] = useState('project')

    const [name, setName] = useState('')

    const [createAnother, setCreateAnother] = useState(false)

    const loadGroups = (offset, query, records = 24, groupType = 'project') => {
        setIsLoading(loadingState(loading, loadingStates.groups, true))

        fetchData(Endpoints.GROUPS(offset, query, records, groupType))
            .then(res => res.json())
            .then(res => {
                setGroups(res.data || [])
            }).catch(e => {
            setError(Strings.CONNECTION_ERROR_MESSAGE)
        })
            .finally(() => setIsLoading(loadingState(loading, loadingStates.groups, false)))
    }

    const createGroup = () => {
        setError('')
        setMessage('')

        if (!name) {
            setError('All fields are required')
            return
        }

        setIsLoading(loadingState(loading, loadingStates.createGroup, true))

        const data = new FormData()
        data.append("name", name)
        data.append("group_type", groupType)

        postData(Endpoints.GROUP, data)
            .then(res => res.json())
            .then(res => {
                if (!res.success) {
                    setError(res.errors?.[0] || res.message)
                    return;
                }

                setMessage(res.data)

                setName('')

                setTimeout(() => {
                    setMessage('')
                    setError('')
                    !createAnother && window.location.assign("/dashboard/category-groups")
                }, 1500)


            }).catch(e => {
            setError(Strings.CONNECTION_ERROR_MESSAGE)
        })
            .finally(() => setIsLoading(loadingState(loading, loadingStates.createGroup, false)))
    }

    const updateGroupDetails = (id) => {
        setError('')
        setMessage('')

        if (!name) {
            setError('All fields are required')
            return
        }

        setIsLoading(loadingState(loading, loadingStates.updateGroup, true))

        const data = new FormData()
        data.append("id", id)
        data.append("name", name)

        postData(Endpoints.UPDATE_GROUP_DETAILS, data)
            .then(res => res.json())
            .then(res => {
                if (!res.success) {
                    setError(res.errors?.[0] || res.message)
                    return;
                }

                setMessage(res.data)

                setTimeout(() => {
                    setMessage('')
                    setError('')
                }, 1500)

            }).catch(e => {
            setError(Strings.CONNECTION_ERROR_MESSAGE)
        })
            .finally(() => setIsLoading(loadingState(loading, loadingStates.updateGroup, false)))
    }

    return {
        groups,
        loading,
        error,
        loadGroups,
        createGroup,
        createAnother,
        setCreateAnother,
        message,
        setMessage,
        loadingStates,
        name,
        setName,
        updateGroupDetails,
        setGroupType,
        groupType,
    }

}
