import React from "react"
import {Input, Label} from "reactstrap"

const TextField = (props) => <div className="mb-3">
    <Label>{props.label}</Label>
    <Input
        type="text"
        className="form-control"
        {...props}
    />
</div>

export default TextField
