import React from "react"

import {Card, CardBody, Container} from "reactstrap"

import Breadcrumbs from "../../components/Common/Breadcrumb"
import MetaTitle from "../../components/MetaTitle"
import FormContainer from "../../components/FormContainer";
import * as PropTypes from "prop-types";
import {ProjectForm} from "../Projects/components/ProjectForm";

const NewProduct = props => {

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTitle title={"Create a product"}/>
                <Container fluid={true}>
                    <Breadcrumbs title={"Dashboard"} breadcrumbItem={"Create a product"} {...props}/>

                    <FormContainer>
                        <Card>
                            <CardBody>
                                <ProjectForm entityType={'product'}/>
                            </CardBody>
                        </Card>
                    </FormContainer>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default NewProduct
