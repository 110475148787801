import React, {useState} from "react"
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle,} from "reactstrap"
import {useAuth} from "../../../hooks/auth";
import ProgressView from "../../ProgressView";

const ProfileMenu = props => {

    const [menu, setMenu] = useState(false)

    const {activeUser, logOutUser} = useAuth()
    const [isLoading, setIsLoading] = useState(false)

    const handleLogOut = e => {
        e.preventDefault()

       logOutUser()
    }

    return (
        <React.Fragment>
            <Dropdown
                isOpen={menu}
                toggle={() => setMenu(!menu)}
                className="d-inline-block"
            >
                <DropdownToggle
                    className="btn header-item waves-effect"
                    id="page-header-user-dropdown"
                    tag="button"
                >
                    {isLoading ? <ProgressView/> : <>
                        <img
                            className="rounded-circle header-profile-user"
                            src={activeUser?.photo || "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"}
                            alt="Header Avatar"
                        />
                        <span
                            className="d-none d-xl-inline-block ms-2 me-1">{activeUser?.name}</span>
                        <i className="mdi mdi-chevron-down d-none d-xl-inline-block"/>
                    </>
                    }
                </DropdownToggle>
                {!isLoading && <DropdownMenu className="dropdown-menu-end">
                    <DropdownItem tag="a" href="/dashboard/settings">
                        {" "}
                        <i className="bx bx-wrench font-size-16 align-middle me-1"/>
                        Settings
                    </DropdownItem>

                    <div className="dropdown-divider"/>
                    <a href={"#"} onClick={handleLogOut} className="dropdown-item">
                        <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger"/>
                        <span>  Log out</span>
                    </a>
                </DropdownMenu>}
            </Dropdown>
        </React.Fragment>
    )
}


export default ProfileMenu
