import numeral from "numeral";
import {ceil} from "lodash";
import moment from "moment";

export const formatNumber = (n, decimals = false) => decimals ? numeral(ceil(n)).format('0,0.00') : numeral(ceil(n)).format('0,0')
export const formatNumberNoRound = (n, decimals = false) => decimals ? numeral(n).format('0,0.00') : numeral(n).format('0,0')

export const loadingState = (loading, key, value) => {
    return {...loading, [key]: value}
}

export const getPillColor = (status) => {
    switch (status) {
        case "sent":
        case "app managed":
            return "primary"
        case "draft":
            return "dark"
        case "paid":
        case "verified":
        case "admin managed":
            return "success"
        case "overdue":
            return "warning"
        case "write off":
        case "not verified":
            return "danger"
        case "void":
            return "dark"
    }
}

export const getShortNumber = n => numeral(n).format('0.0a');

export const getShortNumberND = n => numeral(n).format('0a');

export const getFormarttedNumber = n => numeral(n).format('0,0.00');

export const getFormarttedNumberRatings = n => numeral(n).format('0.0');

export const getFormarttedNumberND = n => numeral(n).format('0,0');

export const getFormattedDate = date => moment(date).add(3, 'hours').format("LLLL")

export const getYears = () => {
    const yearsList = []
    const maxYear = new Date().getFullYear() + 20
    for (let i = 2010; i < maxYear; i++) {
        yearsList.push(i)
    }
    return yearsList
}



