import {useState} from "react";
import {fetchData, postData} from "../util/Server";
import Endpoints from "../util/Endpoints";
import Strings from "../util/Strings";
import {loadingState} from "../util/Util";

const loadingStates = {
    categories: 'categories',
    createCategory: 'createCategory',
    updateCategory: 'updateCategory',
    deleteCategory: 'deleteCategory',
}

export const useCategories = () => {
    const [categories, setCategories] = useState([])
    const [loading, setIsLoading] = useState({})
    const [error, setError] = useState('')
    const [message, setMessage] = useState('')

    const [name, setName] = useState('')
    const [groupID, setGroupID] = useState('')

    const [createAnother, setCreateAnother] = useState(false)

    const loadCategories = (groupID, offset, query, records = 24) => {
        setIsLoading(loadingState(loading, loadingStates.categories, true))

        fetchData(Endpoints.CATEGORIES(groupID, offset, query, records))
            .then(res => res.json())
            .then(res => {
                setCategories(res.data || [])
            }).catch(e => {
            setError(Strings.CONNECTION_ERROR_MESSAGE)
        })
            .finally(() => setIsLoading(loadingState(loading, loadingStates.categories, false)))
    }

    const createCategory = () => {
        setError('')
        setMessage('')

        if (!name) {
            setError('All fields are required')
            return
        }

        setIsLoading(loadingState(loading, loadingStates.createCategory, true))

        const data = new FormData()
        data.append("name", name)
        data.append("group_id", groupID)

        postData(Endpoints.CATEGORY, data)
            .then(res => res.json())
            .then(res => {
                if (!res.success) {
                    setError(res.errors?.[0] || res.message)
                    return;
                }

                setMessage(res.data)

                setName('')

                setTimeout(() => {
                    setMessage('')
                    setError('')
                    //!createAnother && window.location.assign("/dashboard/categories")
                }, 1500)


            }).catch(e => {
            setError(Strings.CONNECTION_ERROR_MESSAGE)
        })
            .finally(() => setIsLoading(loadingState(loading, loadingStates.createCategory, false)))
    }

    const updateCategoryDetails = (id) => {
        setError('')
        setMessage('')

        if (!name) {
            setError('All fields are required')
            return
        }

        setIsLoading(loadingState(loading, loadingStates.updateCategory, true))

        const data = new FormData()
        data.append("id", id)
        data.append("name", name)
        data.append("group_id", groupID)

        postData(Endpoints.UPDATE_CATEGORY_DETAILS, data)
            .then(res => res.json())
            .then(res => {
                if (!res.success) {
                    setError(res.errors?.[0] || res.message)
                    return;
                }

                setMessage(res.data)

                setTimeout(() => {
                    setMessage('')
                    setError('')
                }, 1500)

            }).catch(e => {
            setError(Strings.CONNECTION_ERROR_MESSAGE)
        })
            .finally(() => setIsLoading(loadingState(loading, loadingStates.updateCategory, false)))
    }

    return {
        categories,
        loading,
        error,
        loadCategories,
        createCategory,
        createAnother,
        setCreateAnother,
        message,
        setMessage,
        loadingStates,
        name,
        setName,
        updateCategoryDetails,
        groupID,
        setGroupID
    }

}
