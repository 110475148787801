import React, {useEffect, useState} from "react"

import {Card, CardBody, Col, Container, Modal, Nav, NavItem, NavLink, Row, TabContent, TabPane} from "reactstrap"

import Breadcrumbs from "../../components/Common/Breadcrumb"
import MetaTitle from "../../components/MetaTitle"
import classnames from "classnames";
import ProgressView from "../../components/ProgressView";
import TamashaButton from "../../components/TamashaButton";
import TextField from "../../components/TextField";
import {FormAlert} from "../../components/FormAlert";
import {PageLoader} from "../../components/PageLoader";
import {usePropertys} from "../../hooks/usePropertys";

const styles = {
    tabStyle: {
        cursor: "pointer"
    }
}


const EditPropertyDetail = ({match, location, ...rest}) => {

    const [activeTab, setActiveTab] = useState(0)
    const toggle = tab => setActiveTab(tab)

    const [showDeleteDialog, setShowDeleteDialog] = useState(false)

    const {property} = location.state

    const {
        name,
        setName,
        loading,
        error,
        message,
        loadingStates,
        updatePropertyDetails,
    } = usePropertys()


    useEffect(() => {
        if (!property) {
            return
        }

        setName(property?.name)

    }, [property])

    return (
        <React.Fragment>
            {property ?
                <div className="page-content">
                    <MetaTitle title={property.name}/>
                    <Container fluid={true}>
                        <Breadcrumbs title="Dashboard" breadcrumbItem={property.name} {...rest}/>

                        <Modal
                            isOpen={showDeleteDialog}
                            toggle={() => {
                                setShowDeleteDialog(!showDeleteDialog)
                            }}
                        >
                            <div className="modal-header">
                                <h5 className="modal-title mt-0" id="myModalLabel">
                                    Are you sure your want to delete this role?
                                </h5>
                                <button
                                    type="button"
                                    onClick={() => setShowDeleteDialog(false)}
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <p>
                                    Please note that you cannot delete a role if already assigned to a user. In such a
                                    case, please remove this role from affected users before deleting.
                                </p>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    onClick={() => setShowDeleteDialog(false)}
                                    className="btn btn-secondary waves-effect"
                                    data-dismiss="modal"
                                >
                                    Cancel
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-primary waves-effect waves-light"
                                    onClick={() => {
                                        setShowDeleteDialog(false)

                                    }}
                                >
                                    Confirm
                                </button>
                            </div>
                        </Modal>

                        <Card>
                            <CardBody>

                                {/*<div className={"mb-2 mt-2 d-flex justify-content-end"}>*/}
                                {/*    {loading[loadingStates.deleteProperty] ? <ProgressView/> :*/}
                                {/*        <TamashaButton outline extraClassName={"btn-outline-danger mb-2 me-3"}*/}
                                {/*                       onClick={() => setShowDeleteDialog(true)}><i*/}
                                {/*            className="bx bx-trash font-size-16 align-middle me-2"></i>{" "}Delete*/}
                                {/*            property</TamashaButton>}*/}
                                {/*</div>*/}

                                <FormAlert message={message} error={error}/>

                                <Nav tabs>
                                    <NavItem>
                                        <NavLink
                                            style={styles.tabStyle}
                                            className={classnames({
                                                active: activeTab === 0,
                                            })}
                                            onClick={() => toggle(0)}
                                        >
                                            Update Details
                                        </NavLink>
                                    </NavItem>
                                </Nav>

                                <TabContent activeTab={activeTab} className="p-3 text-muted">
                                    <TabPane tabId={0}>
                                        <Row className={"mt-0"}>
                                            <Col md={12}>
                                                <TextField
                                                    label={"Name"}
                                                    onChange={e => setName(e.target.value)}
                                                    value={name}
                                                />
                                            </Col>
                                        </Row>

                                        <div className={"float-end justify-content-end"}>
                                            {loading[loadingStates.updateProperty] ? <ProgressView/> :
                                                <TamashaButton
                                                    extraClassName={"float-end"}
                                                    onClick={() => {
                                                        updatePropertyDetails(property?.id)
                                                    }}>Update</TamashaButton>}
                                        </div>
                                    </TabPane>
                                </TabContent>

                            </CardBody>
                        </Card>

                    </Container>
                </div>
                : <PageLoader/>
            }

        </React.Fragment>
    )
}

export default EditPropertyDetail
