import React from "react"
import ReactDOM from "react-dom"
import App from "./App"
import * as serviceWorker from "./serviceWorker"
import {BrowserRouter} from "react-router-dom"
import {Provider} from "react-redux"
import store from "./store"
import AuthContextProvider from "./hooks/auth";


const app = (
    <AuthContextProvider>
        <Provider store={store}>
            <BrowserRouter>
                <App/>
            </BrowserRouter>
        </Provider>
    </AuthContextProvider>

)

ReactDOM.render(app, document.getElementById("root"))
serviceWorker.unregister()
