import {useState} from "react";
import {fetchData} from "../util/Server";
import Endpoints from "../util/Endpoints";
import Strings from "../util/Strings";
import {loadingState} from "../util/Util";

const loadingStates = {
    portfolio: 'portfolio',
    createPortfolio: 'createPortfolio',
    updatePortfolio: 'updatePortfolio',
    deletePortfolio: 'deletePortfolio',
}

export const usePortfolio = () => {
    const [portfolio, setPortfolio] = useState([])
    const [loading, setIsLoading] = useState({})
    const [error, setError] = useState('')
    const [message, setMessage] = useState('')



    const loadPortfolio = (offset, query, projectID = "", ownerID = "", userId = "", records = 24) => {
        setIsLoading(loadingState(loading, loadingStates.portfolio, true))

        fetchData(Endpoints.PORTFOLIOS(offset, query, records, ownerID, projectID, userId))
            .then(res => res.json())
            .then(res => {
                setPortfolio(res.data || [])
            }).catch(e => {
            setError(Strings.CONNECTION_ERROR_MESSAGE)
        })
            .finally(() => setIsLoading(loadingState(loading, loadingStates.portfolio, false)))
    }

    return {
        portfolio,
        loading,
        error,
        loadPortfolio,
        message,
        setMessage,
        loadingStates,
    }

}
