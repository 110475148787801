import {useState} from "react";
import {fetchData, postData} from "../util/Server";
import Endpoints from "../util/Endpoints";
import Strings from "../util/Strings";
import {loadingState} from "../util/Util";

const loadingStates = {
    skills: 'skills',
    findSkill: 'findSkill',
    createSkill: 'createSkill',
    updateSkill: 'updateSkill',
    deleteSkill: 'deleteSkill',
}

export const useSkills = () => {
    const [skills, setSkills] = useState([])
    const [loading, setIsLoading] = useState({})
    const [error, setError] = useState('')
    const [message, setMessage] = useState('')

    const [name, setName] = useState('')
    const [photo, setPhoto] = useState('')
    const [description, setDescription] = useState('')
    const [rewardPoints, setRewardPoints] = useState(0)
    const [backgroundColor, setBackgroundColor] = useState('')
    const [duration, setDuration] = useState('')
    const [comesAfter, setComesAfter] = useState('first')
    const [questID, setQuestID] = useState('')
    const [questions, setQuestions] = useState([])
    const [customButtonText, setCustomButtonText] = useState('')
    const [customButtonLink, setCustomButtonLink] = useState('')

    const [skill, setSkill] = useState('')

    const [createAnother, setCreateAnother] = useState(false)

    const loadSkills = (offset, query, records = 24, quest_id = '', categoryId = "") => {
        setIsLoading(loadingState(loading, loadingStates.skills, true))

        fetchData(Endpoints.SKILLS(offset, query, records, quest_id, categoryId))
            .then(res => res.json())
            .then(res => {
                setSkills(res.data || [])
            }).catch(e => {
            setError(Strings.CONNECTION_ERROR_MESSAGE)
        })
            .finally(() => setIsLoading(loadingState(loading, loadingStates.skills, false)))
    }

    const findSkill = (id) => {
        setIsLoading(loadingState(loading, loadingStates.findSkill, true))

        fetchData(Endpoints.SKILL_WITH_ID(id))
            .then(res => res.json())
            .then(res => {
                setSkill(res.data)
            }).catch(e => {
            setError(Strings.CONNECTION_ERROR_MESSAGE)
        })
            .finally(() => setIsLoading(loadingState(loading, loadingStates.findSkill, false)))
    }

    const createSkill = () => {
        setError('')
        setMessage('')

        if (!name || !description || !photo) {
            setError('All fields are required')
            return
        }

        setIsLoading(loadingState(loading, loadingStates.createSkill, true))

        const data = new FormData()
        data.append("file", photo)
        data.append("title", name)
        data.append("description", description)
        data.append("reward_points", rewardPoints)
        data.append("background_color", backgroundColor)
        data.append("duration", duration)
        data.append("comes_after", comesAfter)
        data.append("quest_id", questID)
        data.append("custom_button_link", customButtonLink)
        data.append("custom_button_text", customButtonText)

        postData(Endpoints.SKILL, data)
            .then(res => res.json())
            .then(res => {
                if (!res.success) {
                    setError(res.errors?.[0] || res.message)
                    return;
                }

                setMessage(res.data)
                setName('')

                setTimeout(() => {
                    setMessage('')
                    setError('')
                    !createAnother && window.location.assign("/dashboard/quests/" + questID)
                }, 1500)


            }).catch(e => {
            setError(Strings.CONNECTION_ERROR_MESSAGE)
        })
            .finally(() => setIsLoading(loadingState(loading, loadingStates.createSkill, false)))
    }

    const updateSkillDetails = (id, options = questions) => {
        setError('')
        setMessage('')

        if (!name) {
            setError('All fields are required')
            return
        }

        setIsLoading(loadingState(loading, loadingStates.updateSkill, true))

        const data = new FormData()
        data.append("id", id)
        data.append("title", name)
        data.append("description", description)
        data.append("reward_points", rewardPoints)
        data.append("background_color", backgroundColor)
        data.append("duration", duration)
        data.append("comes_after", comesAfter)
        data.append("questions", JSON.stringify(options))
        data.append("custom_button_link", customButtonLink)
        data.append("custom_button_text", customButtonText)

        postData(Endpoints.UPDATE_SKILL_DETAILS, data)
            .then(res => res.json())
            .then(res => {
                if (!res.success) {
                    setError(res.errors?.[0] || res.message)
                    return;
                }

                setMessage(res.data)

                findSkill(id)

                setTimeout(() => {
                    setMessage('')
                    setError('')
                }, 1500)

            }).catch(e => {
            setError(Strings.CONNECTION_ERROR_MESSAGE)
        })
            .finally(() => setIsLoading(loadingState(loading, loadingStates.updateSkill, false)))
    }

    const updateSkillStatus = (id, verified) => {
        setError('')
        setMessage('')


        setIsLoading(loadingState(loading, loadingStates.updateSkill, true))

        const data = new FormData()
        data.append("id", id)
        data.append("verified", verified)

        postData(Endpoints.UPDATE_SKILL_STATUS, data)
            .then(res => res.json())
            .then(res => {
                if (!res.success) {
                    setError(res.errors?.[0] || res.message)
                    return;
                }

                setMessage(res.data)

                findSkill(id)

                setTimeout(() => {
                    setMessage('')
                    setError('')
                }, 1500)

            }).catch(e => {
            setError(Strings.CONNECTION_ERROR_MESSAGE)
        })
            .finally(() => setIsLoading(loadingState(loading, loadingStates.updateSkill, false)))
    }

    const updateSkillCover = (id) => {
        setError('')
        setMessage('')

        if (!photo) {
            setError('All fields are required')
            return
        }

        setIsLoading(loadingState(loading, loadingStates.updateSkill, true))

        const data = new FormData()
        data.append("id", id)
        data.append("file", photo)

        postData(Endpoints.UPDATE_SKILL_PHOTO, data)
            .then(res => res.json())
            .then(res => {
                if (!res.success) {
                    setError(res.errors?.[0] || res.message)
                    return;
                }

                setMessage(res.data)

                findSkill(id)

                setTimeout(() => {
                    setMessage('')
                    setError('')
                }, 1500)

            }).catch(e => {
            setError(Strings.CONNECTION_ERROR_MESSAGE)
        })
            .finally(() => setIsLoading(loadingState(loading, loadingStates.updateSkill, false)))
    }


    const deleteSkill = (id) => {
        setError('')
        setMessage('')

        setIsLoading(loadingState(loading, loadingStates.deleteSkill, true))

        const data = new FormData()
        data.append("id", id)

        postData(Endpoints.DELETE_SKILL, data)
            .then(res => res.json())
            .then(res => {
                if (!res.success) {
                    setError(res.errors?.[0] || res.message)
                    return;
                }

                setMessage(res.data)

                setTimeout(() => {
                    window.location.assign("/dashboard/quests")
                }, 1500)

            }).catch(e => {
            setError(Strings.CONNECTION_ERROR_MESSAGE)
        })
            .finally(() => setIsLoading(loadingState(loading, loadingStates.deleteSkill, false)))
    }

    return {
        skills,
        loading,
        error,
        deleteSkill,
        loadSkills,
        createSkill,
        createAnother,
        setCreateAnother,
        message,
        setMessage,
        loadingStates,
        name,
        setName,
        photo,
        setPhoto,
        updateSkillCover,
        updateSkillDetails,
        findSkill,
        updateSkillStatus,
        skill,
        description,
        setDescription,
        rewardPoints,
        setRewardPoints,
        backgroundColor,
        setBackgroundColor,
        comesAfter,
        setComesAfter,
        questID,
        setQuestID,
        questions,
        setQuestions,
        duration,
        setDuration,
        customButtonText,
        customButtonLink,
        setCustomButtonLink,
        setCustomButtonText
    }

}
